@import "../scss/utils/reset";
@import "../scss/utils/components";
@import "../scss/utils/mixins";
@import "../scss/utils/vars";

body {
  font-family: $mainFont;
  background-color: #FCFCFC;
  font-size: $mainFontSize;
  color: $mainFontColor;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
  position: relative;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;

}

html, body {
  height: 100%;

}

.l-container {
  max-width: 1710px;
  padding: 0 15px;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

:disabled {
  opacity: .4;
  cursor: not-allowed !important;
}

textarea {
  resize: vertical;
  height: 164px;
  width: 100%;
  padding: 20px;
}

.centered-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

p {
  margin-bottom: 20px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $secondaryFont;
  font-weight: 700;
  letter-spacing: 0.03em;
}

h1 {
  font-size: 54px;
  line-height: 1.23;
  margin-bottom: 60px;
}

h2 {
  font-size: 48px;
  line-height: 1.2;
  margin-bottom: 60px;
}

h3 {
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 30px;
}

h4 {
  font-size: 36px;
  line-height: 1.2;
  margin-bottom: 20px;
}

h5 {
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 20px;
}

h6 {
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 15px;
  letter-spacing: 0.04em;
}

a {
  color: $accentBlue;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

label {
  display: block;
  color: #757D85;
  margin-bottom: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input, textarea, select {
  width: 100%;
  font-family: $secondaryFont;
  font-size: $mainFontSize;
  font-weight: 400;
  height: 60px;
  padding: 0 20px;
  border-radius: 3px;
  background: #FCFCFC;
  border: 1px solid rgba(53, 57, 60, 0.6);
}

input:focus, textarea:focus, select:focus {
  border: 1px solid $accentBlue;
}

input, textarea, button {
  -webkit-appearance: none;
  -webkit-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}

::-webkit-input-placeholder {
  color: #757D85;
}

::-moz-placeholder {
  color: #757D85;
}

:-moz-placeholder {
  color: #757D85;
}

:-ms-input-placeholder {
  color: #757D85;
}

.text-center {
  text-align: center;
}

.main-button, .secondary-button {
  position: relative;
  font-family: $secondaryFont;
  z-index: 10;
  cursor: pointer;
  font-weight: 400;
  min-height: 60px;
  font-size: 14px;
  padding: 17px 50px;
  border: 2px solid transparent;
  letter-spacing: 0;
  text-transform: uppercase;
  outline: none;
  border-radius: 3px;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: .2s ease-in-out;
}

.secondary-button {
  padding: 17px 40px;
}

.main-button {
  color: #fff;
  background-color: $accentBlue;
  border: none;
}

.main-button:after, .j-video__btn-icon:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: .2s linear;
  opacity: 0;
  background-color: #042760;
  z-index: -1;
  background-image: linear-gradient(35deg, rgba(0, 71, 187, 1) 0%, rgba(0, 71, 187, 1) 27%, rgba(46, 123, 248, 0.804359243697479) 48%, rgba(46, 123, 248, 0.8015581232492998) 52%, rgba(0, 71, 187, 1) 73%, rgba(0, 71, 187, 1) 100%);
}

.main-button:hover:after {
  opacity: 1;
}

.secondary-button {
  color: $primaryDark;
  border-color: $primaryDark;
  background-color: transparent;
  &.secondary-button_white{
    border-color: #fff;
    color: #fff;
    &:hover{
      border-color: #fff;
      background-color: #fff;
      color: #35393b;
    }
  }
  &:hover {
    border-color: $accentBlue;
    color: $accentBlue;
  }
}

.pre-title {
  font-size: 16px;
  margin-bottom: 20px;
  color: #0047BB;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-weight: 600;
  font-family: $secondaryFont;
}

.select2 {
  width: 100% !important;
}

.section {
  margin-bottom: 200px;
}

.h-container {
  max-width: 1830px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
}


.header {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  z-index: 2000;
}

.header.fixed {
  position: fixed;
  top: 12px;
  z-index: 2003;
}

.header-top {
  position: absolute;
  z-index: 2001;
  width: 100%;
  left: 0;
  top: 0;
}

.header-top__inner {
  background: #E3E6EA;
  height: 30px;
}

.header-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &__left {
    display: flex;
    align-items: center;
    height: 100%;

    .phone {
      font-size: 12px;
      line-height: 15px;
      font-family: $secondaryFont;
      color: $primaryGray;
    }
  }
}

.social-menu {
  display: flex;
  align-items: center;

  li {
    margin-right: 20px;

    a svg path {
      transition: .2s linear;
    }

    a:hover svg path {
      fill: $accentBlue;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.header__inner {
  height: 86px;
  background: #FFFFFF;
  box-shadow: 0 4px 44px rgba(54, 54, 54, 0.13);
  border-radius: 3px;
}

.header__flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

}

.header__logo {
  flex: 1;
  display: flex;
  justify-content: flex-start;

  img {
    width: 131px;
    height: 35px;
    object-fit: contain;
  }
}

.lang-select a {
  color: $primaryGray;
}

.actions-select {
  margin-right: 13px;
  position: relative;
  height: 100%;

  &:hover {
    .actions-select__dropdown {
      opacity: 1;
      visibility: visible;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  &__text {
    height: 100%;
    cursor: pointer;
    font-size: 12px;
    line-height: 15px;
    font-family: $secondaryFont;
    color: $primaryGray;
    display: flex;
    align-items: center;
    padding: 0 7px;

    svg {
      margin-left: 5px;
      transition: .2s linear;
    }
  }

  &__dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    background: #e3e6ea;
    font-size: 12px;
    width: 100%;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    li {
      padding: 7px;

      a {
        display: block;
        width: 100%;
      }
    }
  }
}

.actions-select.location-select {
  margin-left: -7px;
}

.location-select .actions-select__text {
  color: $accentBlue;
}

.location-select svg path {
  fill: $accentBlue;
}

.menu {
  display: flex;
  align-items: center;
  padding: 0 40px;
  height: 100%;

  &__item {
    margin-right: 40px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    &.active > a{
      color: $accentBlue!important;
    }
    &.active a > .icon-dropdown svg path{
      fill: $accentBlue!important;
    }
    &:last-child {
      margin-right: 0;
    }

    a {
      font-size: 12px;
      text-transform: uppercase;
      color: $mainFontColor;
      font-family: $secondaryFont;
      display: flex;
      align-items: center;
      transition: .2s linear;

      .icon-dropdown {
        display: block;
        margin-left: 5px;
        transition: .1s linear;
      }
    }

    &:hover .sub-menu a {
      color: $mainFontColor;
    }

    &:hover a {
      color: $accentBlue;

      .icon-dropdown {
        transform: rotate(180deg);
      }

      .icon-dropdown svg path {
        fill: $accentBlue;
      }
    }

    &:hover {
      .sub-menu {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
        margin-top: 12px;
        z-index: 100;
      }
    }

  }
}

.all-categories-link {
  color: $accentBlue !important;
  white-space: nowrap;

  svg {
    margin-left: 10px;
    transition: .2s linear;
    position: relative;
    left: 0;
  }

  &:hover svg {
    left: -5px;
  }
}

.sub-menu {
  visibility: hidden;
  display: flex;
  position: absolute;
  right: auto;
  bottom: auto;
  width: auto;
  top: 100%;
  left: 0;
  flex-direction: column;
  padding: 25px;
  color: #333;
  transition: all ease-in-out 0.4s;
  opacity: 0;
  z-index: -1;
  background: #FFFFFF;
  box-shadow: 0 4px 44px rgba(54, 54, 54, 0.13);
  border-radius: 3px;

  &__item {
    margin-bottom: 22px;
    display: flex;

    a {
      white-space: nowrap;
      transition: .2s linear;
    }

    &:hover a {
      color: $accentBlue !important;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.header__controls {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  &-item {
    margin-right: 40px;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    :last-child {
      margin-right: 0;
    }

    &:hover svg path {
      fill: $accentBlue;
    }
  }

  &-item:last-child {
    margin-right: 0;
  }

  svg path {
    transition: .1s linear;
  }
}

.header-search svg {
  transition: .15s linear;
}

.header-search:hover svg {
  transform: rotate(-15deg);
}

.header-hamburger {
  transition: .2s linear;

  span {
    display: block;
    width: 22px;
    height: 2px;
    margin-bottom: 7px;
    transition: .15s linear;
    background-color: $primaryDark;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:hover span {
    background-color: $accentBlue;
  }
}

.header-hamburger-opened {
  span {
    background-color: $accentBlue;
  }

  transform: rotate(90deg);
}

.search-panel-opened {
  svg path {
    fill: $accentBlue;
  }
}

.header.fixed .search-panel, .header.fixed .header-info {
  max-height: calc(var(--vh, 1vh) * 100 - 118px);
}

.search-panel, .header-info {
  max-height: calc(var(--vh, 1vh) * 100 - 156px);

}


.header-info {
  display: block;
  overflow: auto;
  margin-top: 12px;
  background: #FFFFFF;
  box-shadow: 0 4px 44px rgba(54, 54, 54, 0.13);
  border-radius: 3px;
  padding: 60px 100px 75px;
  gap: 60px;
  position: absolute;
  width: calc(100% - 30px);
  left: 15px;
  top: 100%;

  &__col {
    flex: 1;


  }
}

.header-info__col:nth-of-type(4) {
  flex: 20.5%;
  padding-left: 4%;
  padding-right: 5%;
  flex-shrink: 0;

  .info-menu__title {
    max-width: 100%;
  }
}

.is-device.header-info-visible,
.is-device.search-panel-visible {
  position: static;
  overflow: hidden;
  touch-action: none;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: none;
}

.info-menu {
  display: flex;
  justify-content: space-between;

  &__title {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    font-family: $secondaryFont;
    text-transform: uppercase;
    padding-bottom: 18px;
    white-space: nowrap;
    border-bottom: 1px solid rgba(117, 125, 133, .34);
    margin-bottom: 40px;
    max-width: 80%;
  }
}

.info-list {
  &__item {
    margin-bottom: 15px;

    a {
      color: $mainFontColor;
      transition: .2s linear;
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;

        path {
          transition: .21s linear;
        }
      }

      &:hover {
        color: $accentBlue;

        svg path {
          fill: $accentBlue;
        }
      }
    }
  }

  &__sub {
    padding-left: 14px;
    margin-top: 15px;

    li {
      margin-bottom: 15px;

      a {
        color: $primaryGray;
      }
    }
  }
}

.contacts-actions {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;

  &__address {
    margin-bottom: 4px;

    span {
      font-weight: 600;
    }
  }

  &__email {
    color: $primaryGray;
    display: flex;
    align-items: center;
    transition: .2s linear;

    svg {
      margin-right: 10px;

      path {
        transition: .2s linear;
      }
    }

    &:hover {
      color: #0047BB;

      svg path {
        fill: #0047BB;
      }
    }
  }
}

.header-info .social-menu {
  li svg {
    width: 24px;
    height: 24px;
  }
}

.contacts-phones {
  display: flex;

  &__item {
    a {
      display: block;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }

    span {
      display: block;
      font-size: 12px;
      color: $primaryGray;
    }
  }

  &__item:first-child {
    margin-right: 40px;
  }
}

.main-slider {
  position: relative;

  &__img {
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    &_mobile {
      display: none;
    }
  }

  &__item {
    height: 100vh;

    &.inverse {
      .pre-title {
        color: #fff;
      }

      h1 {
        color: #fff;
      }

      .secondary-button {
        border-color: #fff;
        color: #fff;

        &:hover {
          border-color: #fff;
          background-color: #fff;
          color: $mainFontColor;
        }
      }

    }
  }

  &__info {
    max-width: 623px;
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 37%;
    right: 10%;
    margin-left: auto;

    h1 {
      margin-bottom: 60px;
    }
  }
}

.top-screen {
  position: relative;
}

.main-slider-dots {
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 40px;

  &__inner {
    max-width: 623px;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 10%;
    margin-left: auto;

    .slick-dots {
      bottom: 0;
    }

    .slick-dots li button {
      background-color: #fff;
    }

  }
}


.slick-dots {
  position: absolute;
  width: 100%;
  bottom: -45px;
  display: flex;
  justify-content: center;

  li {
    margin-right: 20px;
    display: flex;

    &.slick-active {
      button {
        background-color: #0047BB;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    button {
      width: 50px;
      height: 3px;
      background: #CED1D6;
      opacity: 0.9;
      font-size: 0;
      cursor: pointer;
    }
  }
}

.advantages-section {
  margin-top: 60px;
}

.advantages {
  display: flex;
  gap: 30px;
  justify-content: space-between;

  &__item:nth-child(1) {
    width: 304px;
  }

  &__item:nth-child(2) {
    width: 250px;
  }

  &__item:nth-child(3) {
    width: 224px;
  }

  &__item:nth-child(4) {
    width: 226px;
  }

  &__item:nth-child(5) {
    width: 275px;
  }

  &__icon {
    margin-bottom: 40px;

    svg {
      box-shadow: 0 4px 30px rgba(176, 183, 196, 0.4);

    }
  }

  &__text {
    font-weight: 600;
    font-family: $secondaryFont;
    letter-spacing: 0;
  }
}

.services {
  display: flex;
  gap: 30px;

  &__item {
    width: 20%;
    position: relative;
    display: block;

    &:hover {
      .services__content {
        background: rgba(16, 24, 32, 0.9);
      }

      .services__link {
        svg {
          left: 4px;
        }

        opacity: 1;
      }

      .services__title {
        color: #fff;
      }

      .services__description {
        color: #fff;
      }

      .services__icon svg path {
        fill: #fff;
      }
    }
  }

  &__content {
    padding: 15px 15px 106px 15px;
    background: rgba(243, 243, 245, 0.9);
    border-radius: 3px;
    height: 100%;
    transition: 0.2s linear;
  }

  &__icon {
    margin-bottom: 85px;

    svg path {
      transition: .2s linear;
    }
  }

  &__title {
    margin-bottom: 30px;
    transition: .2s linear;
    font-weight: bold;
    font-size: 27px;
    line-height: 1.2;
    color: $mainFontColor;
  }

  &__description {
    transition: .2s linear;
    margin: 0;
    color: $mainFontColor;
  }

  &__link {
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 3px;
    opacity: 0;
    transition: .2s linear;

    svg {
      position: relative;
      left: 0;
      transition: .2s linear;
    }
  }
}

.gray-section {
  padding-top: 120px;
  background-image: url(../img/gray-section-bg.jpg);
  @include centeredCoverBackground;
}

.section-heading {
  margin-bottom: 120px;

  h2 {
    margin-bottom: 40px;
  }

}

.centered-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-buttons a {
  margin-right: 60px;
}

.centered-buttons a:last-child {
  margin-right: 0;
}

.section-description {
  margin-bottom: 60px;
}

.fade-in-bottom {
  opacity: 0;
  transform: translateY(40px);
  transition: transform .5s, opacity .8s;

}

.fade-in-bottom.is-inview {
  transform: translateY(0);
  opacity: 1;
}

.zoom-image {
  opacity: 0;
  transform: scale(1.4);
  transition: 1.3s cubic-bezier(0, 0.3, .6, 1), opacity .8s;
}

.zoom-image.is-inview {
  transform: scale(1);
  opacity: 1;
}

.j-video {
  position: relative;
}

.j-video .video-js .vjs-big-play-button {
  height: auto;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background-color: transparent;
}

.j-video__btn {
  cursor: pointer;
  width: 244px;
  padding: 10px 20px 10px 10px;
  background: #FFFFFF;
  border-radius: 3px;
  display: flex;
  align-items: center;

}

.j-video__btn-icon {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  background: #0047BB;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.j-video__btn:hover .j-video__btn-icon:after {
  opacity: 1;
}

.j-video__btn-text {
  color: #101820;
  font-size: 16px;
  text-align: left;
  line-height: 19px;
  font-family: Raleway, sans-serif;
}

.vjs-icon-placeholder, .vjs-control-text {
  display: none;
}

//
//.j-video .video-js .vjs-big-play-button {
//  display: none;
//}

.j-video .video-js {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
}

.j-video .video-js:after {
  content: "";
  display: block;
  padding-top: 42%;
}

.j-video .vjs-poster {
  background-size: cover;
}

.statistics {
  display: flex;
  justify-content: space-between;
  gap: 100px;
  &__item {
    width: calc(50% - 20px);
    &:last-child .statistics__name {
      margin: 0;
    }
  }
  &__title {
    width: 35%;
    position: sticky;
    top: 33vh;
    align-self: flex-start;
  }

  &__content {
    width: 59%;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 100px 40px;
  }

  &__img {
    position: relative;
    &:after {
      content: "";
      display: block;
      padding-top: 113%;
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }

  &__name {
    margin-bottom: 100px;
    display: flex;
    align-items: center;
  }

  &__count {
    display: block;
    letter-spacing: 0.03em;
    color: #0047BB;
    font-weight: bold;
    font-size: 62px;
    font-family: $secondaryFont;
    line-height: 1.2;
    margin-right: 10px;
  }

  &__description {
    font-weight: 600;
    line-height: 21px;
    font-size: 18px;
    width: 107px;
  }
  &__info{
    &-title{
      letter-spacing: 0.04em;
      margin-bottom: 40px;
    }
    &-description{
      margin: 0;
    }
  }
}

.statistics{
  &_default &__item:nth-child(even){
    flex-direction: column;
  }
  &_default &__item:nth-child(1){
    margin-top: 176px;
  }
  &_default &__item:nth-child(even):not(:nth-child(2)){
    margin-top: -176px;
  }
  &_default &__img{
    margin-bottom: 40px;
  }
}

.statistics__item:nth-child(even) {
  display: flex;
  flex-direction: column-reverse;
}

.statistics__item:nth-child(even) .statistics__name {
  margin-bottom: 0;
  margin-top: 100px;
}

.img-animated-down,
.img-animated-up,
.img-animated-left {
  transition: 1s ease-out;
  transition-delay: 0.2s;
  position: relative;
  width: auto;
  overflow: hidden;
  visibility: hidden;
}

.img-animated-down {
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
}

.img-animated-up {
  clip-path: polygon(0 0, 0 0, 100% 0, 100% 0);
}

.img-animated-left {
  clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
}

.is-inview .img-animated-down,
.is-inview .img-animated-up,
.is-inview .img-animated-left {
  visibility: visible;
  transform: skewY(0);
}

.is-inview .img-animated-down {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.is-inview .img-animated-up {
  clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
}

.is-inview .img-animated-left {
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
}

.img-animated-down img,
.img-animated-up img,
.img-animated-left img {
  transform: scale(1.4);
  transition: 1.5s ease-out;
}

.is-inview .img-animated-down img,
.is-inview .img-animated-up img,
.is-inview .img-animated-left img {
  transform: scale(1);
}

.connection-slider__item {
  margin: 0 48px;
}

.connection-slider__logo {
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .4;
    transition: all 0.5s ease;
  }

  &:hover img {
    opacity: 1;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    filter: grayscale(0%);
  }
}

.order-security {
  background: #FFFFFF;
  box-shadow: 0 4px 44px rgba(176, 183, 196, 0.4);
  border-radius: 3px;
  padding: 70px 30px;

  &__container {
    display: flex;
    justify-content: space-between;
    max-width: 1558px;
    margin: 0 auto;
  }

  &__title {
    width: 37%;

    h2 {
      margin-bottom: 40px;
    }

    .section-description {
      margin-bottom: 0;
    }
  }
}

.order-security__form {
  width: 57%;
}

.order-form {
  &__row {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }

  &__col {
    width: 33.333%;
    min-width: 0;
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__button {
    flex: 1 1 calc(33.333% - 20px);
    position: relative;

    .main-button {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .wpcf7-spinner {
      margin: 0;
      position: absolute;
      top: 50%;
      z-index: 10;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__privacy {
    font-size: 14px;
    color: $primaryGray;
    line-height: 16px;
    flex: 2 1 calc(66.667% - 20px);
    a {
      color: $primaryGray;
      text-decoration: underline;
    }
  }
}
.order-form__privacy > span {
  display: block;
  max-width: 418px;
}
.select2-container--default .select2-selection--single {
  border: 1px solid rgba(53, 57, 60, 0.6);
  border-radius: 3px;
  background: #fcfcfc;
}

.select2-container .select2-selection--single {
  height: 60px;
  display: flex;
  align-items: center;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: $mainFontColor;
  line-height: 60px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 20px;
  padding-right: 50px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 18px;
  right: 20px;
  width: 24px;
  height: 24px;
  background-image: url(../img/dropdown-icon.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: .2s linear;

}

.select2-dropdown {
  border: 1px solid rgba(53, 57, 60, 0.6);
  border-radius: 3px;
}

.select2-container--default .select2-results__option--selected {
  background-color: transparent;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: $accentBlue;
  color: white;
}

.select2-container--default .select2-results > .select2-results__options {
  padding: 0 10px;
}

.select2-results__option {
  padding: 12px 10px;
  border-radius: 3px;
}

.select2-container--open.select2-container--default .select2-selection--single .select2-selection__arrow {
  transform: rotate(180deg);
  background-image: url(../img/dropdown-icon-blue.svg);
}

.location {
  position: relative;

  h2 {
    margin-bottom: 40px;
  }

  &__title {
    position: absolute;
    right: 0;
    width: 41%;
    top: 95px;
  }

  &__address {
    display: block;
    margin-bottom: 20px;
    width: 205px;
  }

  &__menu {
    display: flex;
    background: #FCFCFC;
    border: 1px solid rgba(53, 57, 60, 0.6);
    border-radius: 3px;
    padding: 10px;
    width: 278px;
    margin: 0 -5px;

    &-item {
      width: 50%;
      text-align: center;
      padding: 11px 5px;
      font-weight: bold;
      cursor: pointer;
      transition: .2s linear;

      &.active {
        background: #E3E6EA;
      }

    }
  }

  &__flex {
    display: flex;
  }

  &__map {
    width: 50%;
    height: 658px;
  }

  &__description {
    width: 41%;
    margin-top: 317px;
    margin-left: auto;

  }

  &__email {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    svg {
      margin-right: 10px;

      path {
        transition: .2s linear;
      }
    }

    span {
      color: $mainFontColor;
      transition: .2s linear;
    }

    &:hover span {
      color: #0047bb;
    }

    &:hover svg path {
      fill: #0047bb;
    }
  }

  &__phone {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    span {
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
  }
}

.location__item:first-child {
  display: block;
}

.location__item {
  display: none;
}
.location_region{
  .location__description{
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  h2{
    margin-bottom: 40px;
  }
}
.location__about{
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 42px;
}
.location__list{
  column-count: 2;
  gap: 50px;
  display: inline-block;
}
.location__list-item{
  margin-bottom: 20px;
  position: relative;
  padding-left: 34px;
}
.location__list-item:before{
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../img/check-icon-black.svg);
}
.arrow-link {
  &_white{
    color: #fff;
    svg path{
      fill: #fff;
    }
  }
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: $secondaryFont;
  letter-spacing: 0.04em;

  span {
    display: block;
    margin-right: 10px;
  }

  svg {
    transition: .2s linear;
    position: relative;
    left: 0;
  }

  &:hover svg {
    left: -5px;
  }

  &_black {
    color: #101820;

    svg path {
      fill: #101820;
    }
  }
}

.reviews__buttons {
  display: flex;
  align-items: center;
  gap: 20px 30px;
  flex-wrap: wrap;
}

.reviews {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 123px);

  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 33%;
    margin-top: 70px;

    h2 {
      margin-bottom: 40px;
    }

    .section-description {
      width: 83%;
    }
  }

  &__content {
    width: 62%;
    display: flex;
    align-items: center;
    margin-bottom: 120px;
  }
}

.reviews-section {
  overflow: hidden;
}

.reviews__right {
  width: 49%;
  transform: translateY(140px);
}

.reviews__img {
  position: relative;
  width: 100%;
  height: 350px;
  background-position: center;
  background-size: cover;
}

.reviews-slider .slick-track {
  display: flex !important;
}

.reviews-slider .slick-slide {
  height: auto;
}

.reviews-slider .slick-slide > div {
  height: 100%;
}

.reviews__arrows {
  margin-left: 60px;
  margin-bottom: 60px;

}

.slider-arrows {
  display: flex;
  align-items: center;
}

.slider-prev {
  margin-right: 34px;
}

.slider-arrow {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.slick-arrow.slick-disabled {
  opacity: .15;
  cursor: auto;
}

.reviews-slider {
  width: 51%;

  &__item {
    background: #F3F3F5;
    padding: 60px;
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &__rating {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    span {
      font-weight: 600;
      font-size: 20px;
    }
  }

  &__date {
    color: $primaryGray;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  &__text {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.72;
    margin-bottom: 60px;
  }

  &__name {
    color: rgba(16, 24, 32, 0.5);
    font-weight: bold;
    letter-spacing: 0.04em;
  }

}

.description-container {
  max-width: 538px;
  margin: 0 auto;
}

.app-container {
  max-width: 1436px;
  padding: 0 15px;
  margin: 0 auto;
}


.app-links {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;

  &__item {
    display: flex;
    align-items: center;

    &:nth-child(1) {
      margin-right: 90px;
    }
  }

  &__qr {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    filter: drop-shadow(0px 0px 74px rgba(28, 28, 28, 0.2));

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__link{
    transition: .2s linear;
    &:hover{
      opacity: .75;
    }
  }

}

.app-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__item {
    margin-bottom: 123px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__left {
    width: 332px;
  }

  &__middle {
    width: 24%;
  }

  &__right {
    width: 342px;
  }

  &__icon {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0 4px 44px rgba(54, 54, 54, 0.13);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
  }

  &__phone {
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &:after {
      content: "";
      display: block;
      padding-top: 204%;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 600;
    font-family: $secondaryFont;
    display: block;
    letter-spacing: 0;
  }
}

.app-section h2 {
  margin-bottom: 40px;
}

.achievements-section {
  background: #E3E6EA;
  padding: 80px 0;
}

.achievements {
  display: flex;
  justify-content: center;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 321px;
  }

  &__logo {
    display: block;
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-bottom: 20px;
  }

  &__description {
    text-align: center;
    display: block;
    font-size: 14px;
    color: #35393B;
  }
}

.heading-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;

  h2 {
    margin-bottom: 0;
  }
}

.info-content {
  display: flex;
  flex-direction: column;
}

.suggestion-label {
  border: 1px solid #0047BB;
  border-radius: 2px;
  font-weight: bold;
  font-size: 10px;
  color: #0047BB;
  padding: 12px 20px;
  font-family: $secondaryFont;
  margin-right: 5px;
}

.buttons-flex {
  display: flex;
  align-items: center;
}

.suggestions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 40px 20px;

  &__item {
    background: #F4F4F6;
    border-radius: 2px;
    display: flex;

    &:nth-child(1) {
      grid-row: 1/-1;

      .suggestion-label {
        margin-bottom: 150px;
      }

      h6 {
        margin-bottom: 40px;
        letter-spacing: 0;
      }

      .suggestions__description {
        margin-bottom: 87px;
      }

      .suggestions__img {
        width: 42%;
      }

      .suggestions__content {
        padding-bottom: 60px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 20px 30px;
    flex-wrap: wrap;
  }

  &__title {
    margin-bottom: 65px;
    font-size: 24px;
    display: block;
    line-height: 1.2;
    color: #101820;
    font-weight: 700;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    flex: 1;

    .suggestion-label {
      margin-bottom: 65px;
    }
  }

  &__img {
    width: 37%;
    background-position: center;
    background-size: cover;
    display: block;
  }
}

.blog-flex {
  display: flex;
  gap: 40px;
}

.blog-aside {
  width: 23.2%;

  &--horizont {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #d0d4d6;
    border-top: 1px solid #d0d4d6;
    padding-top: 40px;
    
    .blog-aside__item {
      margin: 0 40px 0 0;
      border-bottom: none;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.blog-date {
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #757D85;
  margin-left: auto;
}

.blog-general {
  flex: 1;
  display: flex;
  gap: 20px;

  &__img {
    display: block;
    margin-bottom: 30px;
    position: relative;

    &:after {
      content: "";
      display: block;
      padding-top: 73%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__description {
    margin-bottom: 30px;
  }
}

.blog-title {
  font-size: 20px;
  margin-bottom: 30px;
  display: block;
  color: #101820;
  font-weight: 600;
}

.blog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  height: 41px;
}

.blog-aside__item {
  padding-bottom: 36px;
  margin-bottom: 36px;
  border-bottom: 1px solid #D0D4D6;

  &:last-child {
    margin-bottom: 0;
  }
}

.blog-aside__item .blog-header {
  margin-bottom: 20px;
}

.blog-aside__item .blog-title {
  margin-bottom: 20px;
}

.general-about__text {
  overflow: hidden;
  column-count: 2;
  column-gap: 100px;
  position: relative;
  max-height: 174px;
  transition: max-height 0.15s ease-out;
  margin-bottom: 80px;
}

.general-about__text:after {
  content: "";
  display: block;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(0deg, #fcfcfc, hsla(0, 0%, 98.8%, .19));
  left: 0;
}

.general-about .arrow-link svg {
  transform: rotate(90deg);
}

.general-about .arrow-link:hover svg {
  left: 0;
}

.general-about-visible .general-about__text {
  max-height: 5000px;
  transition: max-height 0.35s ease-in-out;
}

.general-about-visible .general-about__text:after {
  display: none;
}

.general-about-visible .arrow-link svg {
  transform: rotate(-90deg);
}

.footer {
  background: #E3E6EA;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    margin-bottom: 60px;
    border-bottom: 1px solid rgba(117, 125, 133, .4);
  }

  &__menu {
    display: flex;
    align-items: center;

    &-item {
      margin-right: 60px;

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 12px;
        color: $mainFontColor;
        text-transform: uppercase;
        font-family: $secondaryFont;
        transition: .2s linear;
      }

      &:hover a {
        color: #0047BB;
      }
    }

  }

  &__logo {
    img {
      width: 170px;
      height: 46px;
      object-fit: contain;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

}

.footer-info {
  margin-bottom: 60px;
}

.footer .contacts-phones__item:first-child {
  margin-right: 0;
}

.footer .contacts-phones__item {
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  a {
    margin-right: 10px;
  }
}

.footer .contacts-actions__address span {
  display: block;
}

.footer .contacts-actions {
  gap: 80px;
  margin-bottom: 30px;
  padding-bottom: 19px;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(117, 125, 133, .34);
  flex-direction: row;
}

.footer .contacts-actions__address {
  margin-bottom: 20px;
}

.footer .info-menu__title {
  margin-bottom: 20px;
}

.footer .contacts-phones {
  flex-direction: column;
}

.footer .contacts-phones__item {
  margin-bottom: 20px;
}

.footer .contacts-phones__item:last-child {
  margin-bottom: 0;
}

.footer .contacts-phones__item span {
  font-size: 16px;
}

.footer-info__col:nth-child(1) .info-menu__title {
  max-width: 100%;
}

.footer-info__col:nth-child(1) {
  width: 29%;
}

.footer-info__col:nth-child(2) {
  width: 15%;
}

.footer-info__col:nth-child(3) {
  width: 17%;
}

.footer-info__col:nth-child(4) {
  width: 16%;
}

.footer-payments {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 118px;

  &__flex {
    width: 260px;
    height: 60px;
    border: 1px solid #CED1D6;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 30px;
    padding: 16px;
  }
}

.footer__copy {
  color: $primaryGray;
  flex: 1;
}

.footer__privacy {
  color: $primaryGray;
  flex: 1;
  text-align: right;
}

.footer__privacy:hover {
  text-decoration: underline;
}

.footer-social {
  li svg {
    width: 24px;
    height: 24px;
  }

}

.footer-info__col:nth-child(5) {
  display: none;
}

.m-search-input {
  padding-left: 80px;
  padding-right: 45px;
}


.search-panel {
  margin-top: 12px;
  background: #fff;
  box-shadow: 0 4px 44px rgba(54, 54, 54, 0.13);
  border-radius: 3px;
  padding: 40px;
  position: absolute;
  perspective: 1000px;
  width: 768px;
  right: 15px;
  top: 100%;
  z-index: 101;
  overflow: auto;

  &--page {
    position: static; 
    perspective: none;
    width: 100%;
    max-height: 100%;
    background-color: inherit;
    box-shadow: none;
    padding: 0;

    .search-panel__input {
      max-width: 688px;
      width: 100%;
    }

    .search-results-count-wrapper {
      padding-top: 26px;
      border-top: 1px solid #B7BCC2;
      margin-top: 86px;
    }
  }

  &__input {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 18px;
  }
}

.search-panel__form {
  width: 100%;
}

.search-results {
  margin-top: 60px;
  padding-bottom: 60px;

  &__title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
    font-family: $secondaryFont;
    letter-spacing: 0.04em;
    color: $mainFontColor;
  }

  &__description {
    color: #35393B;
    margin-bottom: 20px;
  }
}

.search-results li {
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }
}

.search-results-count {
  margin-top: 15px;
  font-size: 14px;
  color: #35393B;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.contacts-phone {
  display: flex;
  align-items: center;
}

.breadcrumbs {
  padding: 40px 0 55px;
  position: relative;
  z-index: 10;
}

.woocommerce-breadcrumb{
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  overflow: auto;
}
.woocommerce-breadcrumb a,
.woocommerce-breadcrumb span {
  color: #101820;
  font-size: 12px;
  white-space: nowrap;
}
.woocommerce-breadcrumb span.last {
  color: #0047BB;
}

.woocommerce-breadcrumb .separator {
  margin: 0 7px;
}

.top-info-section {
  margin-bottom: 100px;
}

body:not(.home) .main {
  padding-top: 136px;
}

.top-info {
  display: flex;
  justify-content: space-between;
  margin-top: -78px;
}

.top-info__col_title {
  width: 51%;
}

.top-info__col_image {
  width: 42%;
  position: relative;
  overflow: hidden;
}

.top-info__title {
  max-width: 775px;
  margin: 0 auto;
  padding: 30% 0;

  h1 {
    transition-delay: .2s;
  }
}

.top-info .offer-slider-wrapper {
  transition-delay: .3s;
}

.top-info__link {
  transition-delay: .3s;
}

.has-slider .top-info__link {
  opacity: 1;
  transform: translateY(0);
  transition: none;
}

.top-info__img {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.top-info h1 {
  letter-spacing: 0;
}

.offer-slider-wrapper {
  position: relative;
  margin-top: 120px;
  margin-bottom: 40px;
  overflow: hidden;
}

.offer-slider {
  width: 120%;
  margin-left: -10%;

  &__img {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    z-index: 20;
    left: 0;
    margin-bottom: 40px;
    transform-style: preserve-3d;
    &:before{
      content: "";
      display: block;
      padding-top: 59%;
    }
    &:after{
      content: "";
      display: block;
      background: radial-gradient(50% 50% at 50% 50%, #6C6D6F 0%, rgba(221, 222, 226, 0) 100%);
      filter: blur(21px);
      transform: translateZ(-1px);
      width: 100%;
      height: 56%;
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
    }
  }

   &__name {
    text-align: center;
    letter-spacing: 0.04em;
    font-weight: bold;
    font-size: 18px;
    font-family: $secondaryFont;
    margin-bottom: 40px;
  }

  &__price {
    text-align: center;
    font-weight: bold;
    font-family: $secondaryFont;
    font-size: 24px;
    line-height: 1.4;
  }
}

.offer-arrows {
  width: 460px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
  z-index: 500;
}


.slick-no-slide .slick-track {
  width: 100% !important;
  text-align: center;
  transform: inherit !important;
}
.slick-no-slide .slick-slide {
  float: none;
  display: inline-block;
}


.offer-slider.three-slides .slick-slide {
  transform: translate(100%, 0);
}

.offer-slider.two-slides .offer-slider__img {
  transform: scale(1);
}
.offer-slider.two-slides .offer-slider__info {
  opacity: 1;
}
.offer-slider .offer-slider__info{
  opacity: 0;
  transition: opacity 0.2s;
  transition-delay: .2s;
  width: 120%;
  margin-left: -10%;
}
.offer-slider .slick-current .offer-slider__info,
.offer-slider .slick-current-clone-animate .offer-slider__info{
  opacity: 1;
  transition: opacity 0.8s;
}

.offer-slider .offer-slider__img{
  transform: scale(.7) translateY(20%);
  transition: transform 0.5s;
}
.offer-slider .slick-current .offer-slider__img,
.offer-slider .slick-current-clone-animate .offer-slider__img{
  transform: scale(1) translateY(0);
}



.offer-slider .slick-slide{
  opacity: 0.4;
  transition: transform 0.5s;
}
.offer-slider .slick-current,
.offer-slider .slick-current-clone-animate{
  opacity: 1;
}



.offer-arrow {
  cursor: pointer;
}

.top-info-section.has-slider .top-info__title {
  padding: 88px 0 0;
}

.security-cards-section h2 {
  margin-bottom: 100px;
}

.security-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  &__col {
    width: calc(50% - 20px);

    &:hover .card {
      box-shadow: 0 4px 44px rgba(176, 183, 196, 0.6);
    }

    &:hover .card__link {
      background-color: #0047BB;

      svg {
        left: 4px;
      }

      svg path {
        fill: #fff;
      }
    }
  }

}

.card {
  display: flex;
  transition: .2s linear;
  height: 306px;

  &__img {
    width: 37%;
    background-position: center;
    background-size: cover;
    height: 100%;
  }

  &__content {
    flex: 1;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #F4F4F6;
    padding: 9% 103px 20px 7%;
    position: relative;
  }

  &__info {
    max-width: 350px;
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.04em;
    margin-bottom: 40px;
    color: #101820;
  }

  &__description {
    color: #35393B;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    line-clamp: 4;
  }
}

.card__link {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dddee2;
  border-radius: 3px;
  margin-left: auto;
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: .2s linear;

  svg {
    position: relative;
    left: 0;
    transition: .2s linear;
  }

  svg path {
    transition: .2s linear;
  }
}

.security-info-section {
  overflow: hidden;
}

.security-info {
  display: flex;
  position: relative;

  &__col {
    width: 50%;
    position: relative;

    &:last-child {
      background-color: #E3E6EA;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: #E3E6EA;
        transform: translateX(100%);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  &__about {
    padding: 60px 25% 114px 0;
  }

  &__text {
    padding-left: 32%;
    padding-right: 15px;
    p:last-child{
      margin-bottom: 0;
    }
  }

  &__img {
    width: 50%;
    height: 83%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 10;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__nav {
    &-item {
      padding-left: 20px;
      color: #757D85;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 0.04em;
      line-height: 1.2;
      padding-top: 20px;
      padding-bottom: 20px;
      font-family: $secondaryFont;
      cursor: pointer;
      transition: .2s linear;
    }
  }

}
.security-info .slick-track{
  transform: none!important;
}
.security-info__item{
  height: 100%;
  padding: 50px 0;
  display: flex!important;
  align-items: center;
  justify-content: center;
}
.security-info__for.slick-slider {
  .slick-list{
    height: 100%;
  }
  .slick-track{
    height: 100%;
  }
  .slick-slide{
    height: 100%;
    &>div{
      height: 100%;
    }
  }
  height: 100%;
}

.security-info__for .slick-list {
  overflow: visible;
}

.security-info__nav.slick-vertical .slick-slide {
  width: 100%!important;
}
.security-info__nav .slick-list {
  padding: 0 !important;
  height: auto!important;
}
.security-info__nav .slick-track{
  height: auto!important;
}
.slick-slide.slick-current .security-info__nav-item {
  color: #101820;
  font-size: 24px;
  padding-left: 0;
}

.slick-slide.slick-current .security-info__nav-item:before {
  opacity: 1;
}
.security-info__nav-item:before{
  content: "-";
  display: inline-block;
  opacity: 0;
}
.slick-slide.slick-current .security-info__nav-item {
  padding: 30px 0;
}
.faq {
  display: flex;
  justify-content: space-between;

  &__title {
    width: 27%;
  }

  &__content {
    width: 66%;
    margin-top: -30px;
    border-bottom: 1px solid rgba(117, 125, 133, .4);
  }

  &__header {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 30px 0;
    border-top: 1px solid rgba(117, 125, 133, .4);
    justify-content: space-between;

    &:first-child {
      border-top: 0;
    }

    &.active svg {
      transform: rotate(180deg);
    }

    svg {
      transition: .2s linear;
    }
  }

  &__question {
    display: block;
    font-size: 18px;
    max-width: 90%;
  }

  &__description {
    display: none;
    padding-bottom: 30px;
    color: #35393B;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.img-info-section {
  overflow: hidden;
}

.img-info {
  display: flex;

  &__col {
    &_image {
      width: 58%;
      position: relative;
    }

    &_content {
      width: 41%;
      background: #E3E6EA;
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: #e3e6ea;
        transform: translateX(100%);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  &__image {
    position: absolute;
    width: calc(58vw + 15px);
    height: 100%;
    top: 0;
    right: 0;
    background-position: 50%;
    background-size: cover;
  }

  &__content {
    padding: 21% 0 21% 23%;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 92%;
  }
}
.consultation-section .order-security{
  background: #35393B;
}
.consultation-section h2,
.consultation-section .section-description,
.consultation-section label{
  color: #fff;
}
.consultation-section .order-form__privacy{
  color: #CBCBCB;
  a{
    color: #CBCBCB;
  }
}
.consultation-section .secondary-button{
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.special-offers-section {
  overflow: hidden;
}
.special-offers-wrapper{
  position: relative;
  margin: 0 -20px;
}
.special-offers{
  .slick-slide{
    padding: 0 20px;
    height: auto;
    > div{
      height: 100%;
    }
  }
  .slick-dots {
    bottom: -60px;
  }
  .slick-track {
    display: flex !important;
    margin-left: 0;
  }
  &__item{
    position: relative;
    height: 100%;
  }
  &__content{
    display: flex;
    align-items: center;
    background: #35393B;
    border-radius: 2px;
    height: 100%;
    padding: 14% 7% 14%;
  }
  &__info{
    position: relative;
  }
  &__title{
    max-width: 320px;
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0;
    margin-bottom: 60px;
    line-height: 28px;
  }
  &__img{
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 320px - 7%);
    height: 100%;
    background-position: right bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.special-offers-arrows{
  position: absolute;
  left: 20px;
  bottom: -40px;
  transform: translateY(100%);
}
.special-offers-link {
  margin-top: 44px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 20;
}
.subscribe-plan-section{
  h2{
    margin-bottom: 100px;
  }
}
.subscribe-plan{
  display: flex;
  gap: 50px;
  &__item{
    width: calc(33.333% - 25px);
    &:hover{
      .subscribe-plan__content{
        box-shadow: 0 4px 44px rgba(176, 183, 196, 0.6);
      }
    }
    &_dark{
      .subscribe-plan__content{
        background: #35393B;
      }
      .subscribe-plan__name{
        h6{
          color: #fff;
        }
        background: #35393B;
        svg path{
          fill: #fff;
        }
      }
      .subscribe-plan__description{
        color: #CBCBCB;
      }
      .subscribe-plan__title{
        color: #CBCBCB;
      }
      .subscribe-plan__price{
        color: #fff;
      }
      .subscribe-plan__list li{
        color: #CBCBCB;
        &:before{
          background-image: url(../img/check-icon-white.svg);
        }
      }
      .subscribe-plan__footer .main-button{
        color: #101820;
        background-color: #fff;
        &:hover{
          border: 1px solid #fff;
          color: #0047bb;
          background-color: #fff;
        }
        &:after{
          display: none;
        }
      }
    }
  }
  &__content{
    height: 100%;
    background-color: #fff;
    box-shadow: 0 4px 44px 2px rgba(176, 183, 196, 0.2);
    padding: 40px 40px 60px 40px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .2s linear;
  }
  &__name{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    svg{
      margin-right: 20px;
      flex-shrink: 0;
    }
    h6{
      margin: 0;
    }
  }
  &__description{
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
  }
  &__img{
    width: 59%;
    margin: auto auto 30px;
    transform-style: preserve-3d;
    position: relative;
    &:before {
      content: "";
      display: block;
      padding-top: 82%;
    }
    &:after {
      content: "";
      display: block;
      background: radial-gradient(50% 50% at 50% 50%, rgba(108, 109, 111, 0.38) 0%, rgba(221, 222, 226, 0) 100%);
      filter: blur(12px);
      transform: translateZ(-1px);
      width: 100%;
      height: 21%;
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
    }
    img{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__rate{
    margin-bottom: 30px;
  }
  &__title{
    text-align: center;
    font-size: 20px;
  }
  &__price{
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    letter-spacing: 0;
    font-family: $secondaryFont;
  }
  &__wrap{
    display: flex;
    justify-content: center;
  }
  &__list{
    display: inline-block;
    margin-bottom: 60px;
    li{
      font-size: 18px;
      margin-bottom: 20px;
      padding-left: 34px;
      position: relative;
      &:last-child{
        margin-bottom: 0;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background-position: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../img/check-icon-black.svg);
      }
    }
  }
  &__footer{
    display: flex;
    justify-content: center;
    align-items: center;
    .main-button{
      border: 1px solid transparent;
    }
  }
}
.features-section{
  background: #E3E6EA;
  padding: 120px 0;
  h2{
    margin-bottom: 100px;
  }
}

.features{
  display: flex;
  gap: 50px;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  &__icon{
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto 40px;
    img{
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
  }
  &__text{
    max-width: 200px;
    margin: 0 auto;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    font-family: $secondaryFont;
    letter-spacing: 0;
  }
}
.licenses-section.section {
  margin-bottom: 325px;
  position: relative;
  overflow: hidden;
}
.licenses-slider-wrapper{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
}
.licenses-title{
  max-width: 725px;
  position: absolute;
  top: 30px;
  left: 15px;
  .section-description{
    max-width: 544px;
  }
}
.licenses-slider{
  &__img{
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: contain;
    }
  }
}
.licenses-slider-for {
  width: 37%;
  margin: -50px 0;
  transform: translateY(75px);
  .slick-slide{
    padding-top: 30px;
  }
  .licenses-slider__img{
    position: relative;
    width: 73%;
    margin: 0 auto;
    box-shadow: 0 4px 30px rgba(176, 183, 196, 0.4);
    &:after {
      content: "";
      display: block;
      padding-top: 141%;
    }

  }
  .licenses-slider__title {
    width: 100%;
    position: relative;
    z-index: -1;
    height: 130px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f3f5;
    span{
      font-weight: 700;
      font-size: 24px;
      line-height: 1.2;
      text-align: center;
      margin-top: -20px;
      font-family: $secondaryFont;
    }
    &:after{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 180%;
      transform: translateY(-100%);
      background: #f3f3f5;
    }
  }
}

.licenses-slider-nav{
  width: calc(56% + 132px);
  margin-left: -66px;
  margin-top: -40px;
  margin-bottom: -50px;
  .slick-slide{
    padding: 0 66px;
  }
  .licenses-slider__item{
    padding: 40px 0;
  }
  .licenses-slider__img{
    position: relative;
    cursor: pointer;
    box-shadow: 0 4px 30px rgba(176, 183, 196, 0.4);

    &:after {
      content: "";
      display: block;
      padding-top: 142%;
    }
  }
  .licenses-slider__title{
    display: none;
  }
}
.licenses-nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 56%;
  margin-top: 94px;
}

.service-slider-section h2{
  margin-bottom: 100px;
}
.service-slider{
  .slick-track {
    display: flex!important;
    align-items: center;
  }
  &__item{
    display: flex!important;
    justify-content: center;
    width: 100%;
    margin-left: 0;
  }
  &__img{
    background-size: cover;
    background-position: center;
    transform-origin: 50% 50%;
    transform: scale(.7) translateY(0);
    transition: transform .5s;
    width: 56%;
    &:before {
      content: "";
      display: block;
      padding-top: 111%;
    }
  }
  &__info{
    width: 42%;
    display: none;
    &-inner{
      padding-left: 88px;
      position: relative;
    }
    &-count{
      position: absolute;
      font-weight: bold;
      color: #0047BB;
      letter-spacing: 0.04em;
      font-family: $secondaryFont;
      font-size: 36px;
      left: -28px;
      top: 10px;
      padding-left: 60px;
      transform: rotate( -90deg);
      &:before{
        content: "";
        display: block;
        background: #101820;
        width: 50px;
        height: 2px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

    }
    &-title{
      padding-top: 40px;
      margin-bottom: 40px;
    }
    &-description{
      margin: 0;
    }
  }
}
.service-slider .slick-current-clone-animate .service-slider__info,
.service-slider .slick-current .service-slider__info {
  display: flex;
  align-items: center;
}


.service-slider .slick-current-clone-animate .service-slider__img,
.service-slider .slick-current .service-slider__img {
  transform: scale(1) translateY(0);
  width: 47%;
}

.service-slider .slick-current-clone-animate .service-slider__img:before,
.service-slider .slick-current .service-slider__img:before {
  padding-top: 107%;
}


.service-slider .slick-current-clone-animate .service-slider__item,
.service-slider .slick-current .service-slider__item {
  width: 134%!important;
  margin-left: -17%;
  justify-content: space-between;
}

.service-slider .slick-slide {
  opacity: .6;
  transition: transform 1s;
}

.service-slider .slick-current,
.service-slider .slick-current-clone-animate {
  opacity: 1;
}

.service-arrows {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
  margin-top: -30px;
  margin-left: -90px;
}

.payment{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  &__col{
    width: calc(33.333% - 20px);
    &:hover{
      .payment__inner{
        box-shadow: 0 4px 44px rgba(176, 183, 196, 0.6);
      }
    }
  }
  &__inner{
    background: #FFFFFF;
    box-shadow: 0 4px 44px rgba(176, 183, 196, 0.4);
    border-radius: 3px;
    padding: 40px;
    transition: .2s linear;
  }
  &__icon{
    margin-bottom: 20px;
    img{
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
  &__title{
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
  }
  &__pretitle{
    font-weight: 600;
    font-family: $secondaryFont;
    margin-bottom: 20px;
  }
  &__list{
    padding-left: 23px;
    margin-left: -7px;
    margin-bottom: 20px;
    list-style: decimal;
    position: relative;
    max-height: 55px;
    overflow: hidden;
    transition: max-height .3s cubic-bezier(0, 1, 0, 1);
    &-item{
      list-style: decimal;
    }
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 30px;
      position: absolute;
      bottom: 0;
      background: linear-gradient(0deg ,#ffffff,hsla(0, 0%, 100%, 0.54));
      left: 0;
    }
    &.payment-list-visible{
      max-height: 500px;
      transition: max-height .45s ease-in-out;
      &:after{
        display: none;
      }
    }
  }
  &__footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    gap: 30px;
    flex-wrap: wrap;

  }

}
.expand-link{
  svg{
    transform: rotate(90deg);
  }
  &:hover{
    svg{
      left: 0;
    }
  }
  &.text-visible{
    svg{
      transform: rotate(-90deg);
    }
  }
}

.arrow-link .expand-link__close{
  display: none;
}
.arrow-link.expand-link.text-visible .expand-link__close{
  display: block;
}
.arrow-link.expand-link.text-visible .expand-link__open{
  display: none;
}

// Contact page
.contact-main {
  margin-top: -226px;
  padding: 226px 0 90px;
  background-image: url(../img/contact-gray-bg.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 60px;

  .contact-heading-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .contact-heading__title {
    margin-bottom: 0;
  }
}

.contact-panel {
  .contact-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .item-col {
    &:first-child {
      margin-right: 40px;
    }
  }

  .item__title {
    font-weight: 600;
    max-width: 244px;
  }

  .item__subtitle {
    font-size: 14px;
  }

  .contact-list-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 40px 39px;
    width: 33.333%;
    letter-spacing: normal;
    font-feature-settings: 'pnum' on, 'lnum' on;

    &::before {
      content: '';
      position: absolute;
      left: 17px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 160px;
      opacity: .34;
      background-color: #757D85;
    }
  }

  .item-list__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .item-list__link {
    display: flex;
    align-items: center;

    span {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

// Tariffs page
.tariffs-section {
  margin-bottom: 120px;
  letter-spacing: normal;
  font-feature-settings: 'pnum' on, 'lnum' on;

  .tariffs-heading {
    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
    }
  }

  .tariffs__title {
    margin-bottom: 0;
  }

  .tariffs-content {
    margin-bottom: 40px;
  }

  .tariffs-table {
    border-top: 1px solid #8F979D;
    border-bottom: 1px solid #8F979D;
  }

  .tariffs-table-row {
    display: flex;
    border-bottom: 1px solid #8F979D;

    &:last-child {
      border-bottom: none;
    }
  }

  .tariffs-table-heading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #F4F4F6;
    padding: 0 35px;
    max-width: 490px;
    width: 100%;
    margin-right: 110px;

    .tariffs-table__title {
      margin: 0 auto 28px 0;
      text-transform: none;
    }

    .tariffs-table__link {
      font-weight: 600;
    }
  }

  .tariffs-table-condition {
    padding: 28px 0;
    margin-right: auto;

    .tariffs-condition {
      display: flex;
      margin-bottom: 26px;

      &:last-child {
        margin-bottom: 0;
      }

      .tariffs-condition__text--small {
        margin: 0;
      }
    }

    .tariffs-condition-side {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .tariffs-condition__text {
      margin-bottom: 6px;

      &--small {
        font-size: 14px;
      }
    }
  }

  .tariffs-table-fee {
    margin: 0 186px 0 auto;
    padding: 25px 0;

    .tariffs-fee {
      text-align: center;
      margin-bottom: 20px;
    }

    .tariffs-fee__text {
      margin-bottom: 5px;
    }

    .tariffs-fee__price {
      font-size: 20px;
      font-weight: 700;
      line-height: normal;
      color: #101820;
      margin: 0;
      font-family: $secondaryFont;
    }
  }

  .tariffs-table-button {
    margin: auto 0;
  }

  .tariffs-footer {
    &-inner {
      display: flex;
      justify-content: flex-end;
    }
  }
}

// Observation section
.observation-section {
  .observation-section-inner {
    display: flex;
    align-items: center;
  }

  .observation-side {
    &:first-child {
      max-width: 577px;
    }
    &:last-child {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  
  .observation-images {
    display: flex;
    position: relative;

    .observation-decoration {
      max-width: 339px;
    }

    .observation-image {
      max-width: 636px;
    }
  }

  .observation-content {
    margin-bottom: 85px;

    .observation__title {
      margin-bottom: 40px;
    }
  }

  .observation-controls {
    display: flex;
    align-items: center;

    .observation__button {
      margin-right: 60px;
    }
  }
}

// Smart house section
.smart-house-section {
  position: relative;

  .smart-house-slide-inner {
    display: flex;
    align-items: center;

    .smart-house-side {
      &:first-child {
        max-width: 905px;
        width: 100%;
      }

      &:last-child {
        width: 100%;
      }
    }

    .smart-house__title {
      margin-bottom: 80px;
      margin-left: 120px;
    }

    .smart-house-article {
      margin-bottom: 65px;
      padding: 105px 0 105px 120px;
      background-color: #E3E6EA;

      .article__text, .article__title {
        max-width: 470px;
        width: 100%;
      }

      .article__title {
        margin-bottom: 60px;
      }
    }

    .smart-house__link {
      font-weight: 600;
    }

    .smart-house-links {
      margin-left: 120px;
    }
  }

  .smart-house__image {
    width: 100%;
  }

  .smart-house-slider-controls {
    position: absolute;
    right: 190px;
    bottom: 2.5vw;
  }
}

// General blog page
.blog-section {

  &.blog-page {
    .blog-aside {
      margin-bottom: 120px;
    }
  }

  .blog-main {
    margin-bottom: 120px;
  }

  .blog-main__item {
    background: #F4F4F6;
    border-radius: 2px;
    display: flex;

      .blog-main__label {
        border: 1px solid #0047bb;
        border-radius: 2px;
        font-weight: 700;
        font-size: 10px;
        color: #0047bb;
        padding: 7px 20px;
        font-family: Montserrat,sans-serif;
        margin-bottom: 30px;
      }

      h6 {
        margin-bottom: 40px;
        letter-spacing: 0;
      }

      .blog-main__description {
        margin-bottom: 32px;

        p {
          margin: 0;
        }
      }

      .blog-main__img {
        width: 74.5%;
        background-position: center;
        background-size: cover;
        display: block;
      }

      .blog-main__content {
        padding: 67px 25px;
      }
    
  }

  .blog-main__title {
    margin-bottom: 30px;
    font-size: 20px;
    display: block;
    line-height: 1.2;
    color: #101820;
    font-weight: 600;
    font-family: $secondaryFont;
  }

  .blog-main__buttons {
    .arrow-link {
      font-size: 16px;
    }
  }

  .blog-main__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    flex: 1;

    .blog-main-label {
      margin-bottom: 65px;
    }
  }

  .blog-articles {
    display: flex;
    flex-wrap: wrap;

    &--bigger {
      margin-bottom: 80px;
    }

    .articles__item-holder {
      padding: 20px 10px;
      width: 50%;
    }

    .articles__item {
      background: #F4F4F6;
      border-radius: 2px;
      display: flex;
      flex-direction: row-reverse;

      &:nth-child(2n+1) {
        margin-right: 40px;
      }
  
        .article__label {
          border: 1px solid #0047bb;
          border-radius: 2px;
          font-weight: 700;
          font-size: 10px;
          color: #0047bb;
          padding: 12px 20px;
          font-family: Montserrat,sans-serif;
          margin-bottom: 30px;
        }
  
        h6 {
          margin-bottom: 40px;
          letter-spacing: 0;
        }
  
        .article__description {
          margin-bottom: 30px;

          p {
            margin: 0;
          }
        }
  
        .article__img {
          width: 47.6%;
          background-position: center;
          background-size: cover;
          display: block;
        }

        &--bigger {
          &:nth-child(odd) {
            margin-right: 0;
          }

          .article__label {
            margin-bottom: 50px;
          }
          
          .article__title {
            font-size: 24px;
            margin-bottom: 50px;
          }
        }
    }
  
    .article__title {
      margin-bottom: 30px;
      font-size: 20px;
      display: block;
      line-height: 1.2;
      color: #101820;
      font-family: $secondaryFont;
      font-weight: 600;

      .marked {
        color: #0047BB;
      }
    }
  
    .article__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 62px 25px;
      flex: 1;
  
      .article-label {
        margin-bottom: 30px;
      }
    }
  }

  .articles__button {
    display: block;
    margin: 0 auto;

    &:hover {
      svg {
        path {
          fill: #0047BB;
        }
      }
    }
    
    span {
      margin-right: 10px;
    }

    svg {
      path {
        transition: fill .3s;
      }
    }
  }
}

// Article page
.article-section {
  .article-section-inner {
    display: flex;
    justify-content: space-between;
  }

  .article {
    max-width: 1113px;
    width: 100%;
    margin-right: 177px;
  }

  h1 {
    font-size: 30px;
    margin: 0 0 20px 0;
  }

  .article__date {
    display: inline-block;
    font-size: 16px;
    font-feature-settings: "pnum" on,"lnum" on;
    color: #757d85;
    margin-left: auto;
    margin-bottom: 50px;
  }

  .article__block {
    margin: 0 0 50px 0;
  }

  p {
    font-size: 18px;
    margin: 0 0 20px 0;
  }

  blockquote {
    position: relative;
    max-width: 390px;
    width: 100%;
    margin: 0 auto 50px;
    font-family: $secondaryFont;
    font-size: 24px;
    font-weight: 600;

    &::after {
      content: '';
      position: absolute;
      right: -115px;
      top: -18px;
      background-image: url(../img/blockquote-icon.svg);
      background-size: 39px 32px;
      background-repeat: no-repeat;
      width: 39px;
      height: 32px;
    }
  }

  strong {
    display: inline-block;
    margin: 0 0 50px 0;
    font-size: 20px;
    font-weight: 600;
  }

  figure {
    max-width: 890px;
    width: 100%;
    margin-bottom: 50px;

    img {
      display: inline-block;
      margin-bottom: 20px;
    }

    figcaption {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }

  ul, ol {
    margin-bottom: 50px;
    padding-left: 40px;
  }

  ul {
    li {
      list-style-type: disc;
      margin-bottom: 15px;
    }
  }

  ol {
    li {
      margin-bottom: 15px;
      list-style-type: auto;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
  }

  .social-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid #D0D4D6;
    margin-top: 100px;

    strong {
      margin-bottom: 0;
    }
  }
}

// Search page
.search-section {
  .search-results__mark {
    color: #0047BB;
  }

  .breadcrumbs {
    padding-bottom: 85px;
  } 
}

// Search page
.career-section {
  margin-top: -226px;
  padding-top: 226px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  .career-contetn-side {
    padding-bottom: 140px;
  }

  .career-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .career-heading {
    margin-right: 40px;
  }

  .career__subtitle {
    font-size: 16px;
    margin-bottom: 15px;
    color: #0047bb;
    letter-spacing: .04em;
    text-transform: uppercase;
    font-weight: 600;
    font-family: $secondaryFont;
  }

  .career__title {
    margin: 0 0 40px 0;
  }

  .career-text {
    max-width: 680px;
    width: 100%;
  }
}

// Erorr section
.erorr-section {
  position: relative;
  background-image: url(../img/404_bg.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  &.section {
    margin: -136px 0 0;
    padding: 236px 0 225px;
  }

  .l-container {
    position: static;
  }

  .erorr-section-decor {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    max-width: 540px;
    max-height: 750px;
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .erorr-content__title {
    text-align: center;
    font-size: 522px;
    line-height: .7;
    color: #122E46;
    margin: 0 0 75px;
  }

  .erorr-message {
    display: flex;
    position: relative;
    z-index: 3;
    justify-content: space-between;
    color: #fff;
  }

  .erorr-message-side {
    display: flex;
    flex-direction: column;

    &:first-child {
      max-width: 366px;
      width: 100%;
    }

    .main-button {
      margin-bottom: 20px;
    }
  }

  .erorr-message__title {
    font-size: 18px;
    letter-spacing: 0.02em;
  }

  .erorr-message__text {
    font-size: 16px;
    letter-spacing: 0.01em;
  }
}

// Career page
.сertificate-section {
  background-color: #E3E6EA;
  
  .certificate-section-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 295px 0;
  }
  
  .certificate-image-holder {
    max-width: 770px;
    width: 100%;
  
    .certificate__img {
      width: 100%;
      margin: -295px 0;
    }
  }
  
  .certificate-content {
    max-width: 650px;
    width: 100%;
    padding: 20px;
  
    .certificate__title {
      margin: 0 0 40px; 
    }
  }
}

// Study section
.study-section {
  .study-section-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .study-section-heading {
    max-width: 995px;
    width: 100%;
    margin: 0 0 60px;
    text-align: center;
  }

  .study-section__title {
    margin: 0 0 60px;
  }

  .study-section__text {
    margin: 0;
    letter-spacing: 0.01em;
  }

  .study-section-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .study-col {
    max-width: 405px;
    width: 100%;
  }

  .study-col__icon {
    display: inline-block;
    padding: 16px 14px;
    background-color: #fff;
    box-shadow: 0px 4px 44px rgba(54, 54, 54, 0.13);
    border-radius: 3px;
    margin: 0 0 40px;
  }

  .study-col__title {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 20px;
  }

  .study-col-list {
    padding-left: 35px;
  }

  .study-col-list__item {
    position: relative;
    margin: 0 0 10px;
    font-size: 16px;
    letter-spacing: 0.01em;

    &::after {
      content: '';
      position: absolute;
      left: -35px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-image: url(../img/check-line.png);
      background-size: cover;
      background-repeat: no-repeat;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Career main section
.career-main-section {
  margin: -136px 0 40px;
  padding-top: 136px;

  .career-main__title {
    margin: 0 0 60px;
    letter-spacing: 0.01em;
  }

  .career-main__img {
    max-height: 417px;
    min-height: 300px;
    object-fit: cover;
  }

  .career-main-content {
    background-color: #E3E6EA;
  }

  .career-main-content-inner {
    display: flex;
    justify-content: space-between;
    padding: 80px 0;
  }

  .career-main-side {
    max-width: 830px;
    width: 100%;

    &:last-child {
      max-width: 500px;
      width: 100%;
      margin-left: 20px;
    }
  }

  .career-list {
    .career-main-list__item {
      display: flex;
      align-items: center;
      margin: 0 0 20px;

      a {
        display: flex;
        align-items: center;
        color: #101820;
      }

      svg {
        margin-right: 10px;
      }
      
      span {
        letter-spacing: 0.01em;
        font-feature-settings: 'pnum' on, 'lnum' on;
      }
    }
  }
}

// Invitation section
.invitation-section {
  .invitation-section-inner {
    box-shadow: 0px 4px 44px rgba(176, 183, 195, 0.4);
    padding: 80px 20px;
  }

  .invitation-holder {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto; 
  }

  input, select, textarea {
    border: 1px solid rgba(53, 57, 60, 0.6);
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    height: 60px;
    padding: 20px;
  }
  
  strong {
    display: inline-block;
    margin-bottom: 30px;
    line-height: 1.5;
  }

  textarea {
    resize: none;
    height: 165px;
    margin-bottom: 5px;
  }

  .invitation-form {
    display: flex;
  }
  
  .invitation__title {
    text-align: center;
    font-size: 24px;
    margin: 0 0 20px;
    font-weight: 600;
  }

  .invitation__subtitle {
    display: block;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    margin: 0 0 20px;
  }

  .invitation-contacts {
    margin: 0 0 60px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    span {
      letter-spacing: 0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #101820;
    }

    .invitation__phone {
      margin-right: 40px;
      margin-bottom: 5px;

      svg {
        margin-right: 10px;
      }
    }
  }

  .invitation-form-side {
    width: 50%;
    padding-right: 100px;

    &:last-child {
      padding: 0 0 0 100px;
      border-left: 1px solid #D0D4D6;
    }
  }

  .invitation-form__item {
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .invitation-form-note {
    display: block;
    text-align: right;
    letter-spacing: 0.01em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: 11px;
  }

  .drag-and-drop {
    cursor: pointer;
    display: flex;
    padding: 25px 50px;
    border: 1px dashed rgba(53, 57, 60, 0.6);
    margin-bottom: 46px;

    .drag-and-drop-col {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .drag-and-drop__text {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #5F6468;

    &--small {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.01em;
      color: #7C8286;
    }
  }
}

// Managers section
.managers-section {
  margin-top: -136px;
  padding-top: 136px;

  .managers-list {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
  }

  .manager-card-holder {
    padding: 10px;
    width: 33.333%;
  }

  .manager-card {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 44px rgba(176, 183, 196, 0.4);
    padding: 20px;
    transition: background .3s;
    min-height: 320px;

    &:hover {
      background: #35393B;

      .manager-card__position {
        opacity: 1;
        visibility: visible;
      }
      
      .manager-card__name {
        color: #fff;
      }

      .manager-card__phone, .manager-card__email {
        svg {
          path {
            fill: #fff;
          }
        }

        span {
          color: #fff;
        }
      }
    }
  }

  .manager-card__img-holder {
    display: flex;
    align-items: center;
    margin-right: 20px;
    max-width: 207px;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .manager-card__img {
    width: 100%;
  } 

  .manager-card-content {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .manager-card__position {
    display: inline-block;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;
    padding: 10px 15px;
    background-color: #0047BB;
    color: #fff;
    font-size: 14px;
    margin: 10px 0 30px;
  }

  .manager-card__name {
    max-width: 188px;
    font-weight: bold;
    font-size: 18px;
    color: #101820;
    letter-spacing: 0.04em;
    padding-bottom: 23px;
    border-bottom: 1px solid #9B9B9B;
    margin-bottom: 20px;
    transition: color .3s;
  }

  .manager-card__phone, .manager-card__email {
    display: flex;
    align-items: center;

    span {
      font-size: 16px;
      letter-spacing: 0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      transition: color .3s;
    }

    svg {
      margin-right: 10px;

      path {
        fill: #0047BB;
        transition: fill .3s;
      }
    }
  }

  .manager-card__phone {
    margin-bottom: 23px;
  }

  .managers-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 60px;

    .managers__title {
      margin: 0 20px 0 0;
    }

    .managers-heading-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      flex-shrink: 1;
      
      select, input {
        width: 100%;
      }

      .select2-selection__rendered {
        color: #757D85;
      }
    }
    
    .managers-heading-field {
      max-width: 547px;
      width: 100%;

      &--small {
        max-width: 285px;
      }
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

// Table section
.table-section {
  .table-section-inner {
    background-color: #fff;
    padding: 80px 60px;
    box-shadow: 0px 4px 44px rgba(176, 183, 196, 0.4);
    border-radius: 3px;
  }

  .table__title {
    margin: 0 auto 60px;
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    width: 100%;
  }

  .table-wrapper {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #F4F4F6;
    }

    &::-webkit-scrollbar-thumb {
      background: #E7EAEE;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #E7EAEE;
    }
  }

  .table {
    display: flex;
    width: 100%;
    border: 1px solid #757D85;
    min-width: 1300px;
  }

  .table-col {
    width: 16.666%;
    border-right: 1px solid #757D85;

    &:last-child {
      border-right: none;
    }
  }

  .table-row {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #757D85;
    text-align: center;
    min-height: 54px;
    font-size: 16px;
    letter-spacing: 0.01em;
    padding: 0 10px;
    font-feature-settings:  'pnum' on, 'lnum' on;

    &:last-child {
      border-bottom: none;
    }

    &--title {
      background: #FCFCFC;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.01em;
      padding: 30px 10px;
    }
  }
}

// Special offer section
.special-offer-slider-section {
  position: relative;
  padding-bottom: 40px;

  .l-container {
    position: static;
  }

  .offer-slide {
    background: #F4F4F6;
    border-radius: 2px;
  }

  .offer-slide-figure {
    img {
      display: block;
      min-height: 300px;
      object-fit: cover;
      height: 100%;
    }
  }

  .offer-slide-content {
    display: flex;
    justify-content: space-between;
    padding: 40px 60px;
  }

  .offer-slide-side {
    max-width: 515px;
    width: 100%;

    &:first-child {
      max-width: 265px;
      margin-right: 20px;
    }
  }

  .main-slider-dots__inner {
    z-index: -1;
    max-width: none;
    left: 50%;
    right: unset;
    transform: translateX(-50%);
    bottom: 0;

    .slick-dots {
      li {
        &.slick-active {
          button {
            background-color: #0047BB;
          }
        }

        button {
          background-color: #DDDEE2;
        }
      }
    }
  }

  .offer-slide__title {
    line-height: 1.4;
    letter-spacing: .01em;
    margin: 0 0 22px;

    .offer-slide__title-marker {
      color: #0047BB;
    }
  }

  .offer-slide__text {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.01em;
    margin: 0;
  }
}

// Licenses section
.licenses-section {
  .licenses-section-list {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
  }

  .licenses-section-list-item {
    width: 33.333%;
    padding: 10px;
  }

  .list-item__link {
    display: flex;
    align-items: center;
    background: #F2F3F7;
    padding: 20px;

    svg {
      margin-right: 26px;
    }

    span {
      font-size: 16px;
      line-height: 18px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #35393B;
    }
  }
}

.licens-popup-holder {
  position: fixed;
  z-index: 102;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;
}

.licens-popup {
  position: absolute;
  z-index: 103;
  left: 50%;
  top: 156px;
  bottom: 50px;
  overflow: hidden;
  transform: translate3d(-50%, 0, 0);
  padding: 90px 200px;
  background-color: #fff;
  box-shadow: 0px 4px 44px rgba(54, 54, 54, 0.07);

  .licens-popup-inner {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .licens-popup-list {
      max-width: 750px;
      max-height: 700px;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #F4F4F6;
    }

    &::-webkit-scrollbar-thumb {
      background: #E7EAEE;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #E7EAEE;
    }

    .licens-popup__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-bottom: 20px;
    }
  }

  .licens-popup__close {
    position: absolute;
    right: 27px;
    top: 27px;
    width: 16px;
    height: 16px;
    opacity: 1;
    cursor: pointer;

    &:hover {
      opacity: .3;
    }
    &:before, &:after {
      position: absolute;
      left: 7px;
      content: ' ';
      height: 16px;
      width: 2px;
      background-color: #333;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.licens-popup-overflow {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(206, 209, 214, .5);
}

body {
  &.popup-licens--open {
    overflow: hidden;

    .licens-popup-holder {
      opacity: 1;
      visibility: visible;
    }

    .licens-popup-overflow {
      opacity: 1;
      visibility: visible;
    }
  }
}

// Special offer page
.special-offer {
  display: flex;
  flex-wrap: wrap;

  .special-offer__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 60px;
    width: 100%;

    .special-offer__button {
      background: none;
  
      span {
        margin-right: 10px;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: #101820;
      }
    }
  }

  .special-offer__title {
    margin: 0 10px 0 0;
    letter-spacing: 0.03em;
  }

  .special-offer__img {
    width: 40%;

    img {
      width: 100%;
    }
  }
  
  .special-offer__info {
    width: 60%;
    padding-left: 162px;

    .special-offer__button {
      margin-top: 120px;
    }
  }
  
  .special-offer__label {
    display: inline-block;
    margin: 0 0 60px;
  }

  .special-offer__text {
    margin-bottom: 20px;
  }
}


@media screen and (min-width: 1920px) {
  .service-slider__info {
    width: 478px;
    margin-right: 133px;
  }
  .service-slider .slick-current-clone-animate .service-slider__img,
  .service-slider .slick-current .service-slider__img {
    width: 544px;
  }
  .service-slider .slick-current-clone-animate .service-slider__item,
  .service-slider .slick-current .service-slider__item {
    justify-content: center;
  }
}
@media screen and (max-width: 1800px) {
  .header-info {
    padding: 60px 75px 75px;
  }

}

@media screen and (max-width: 1700px) {
  .service-slider__info-title {
    padding-top: 30px;
    font-size: 22px;
    margin-bottom: 30px;
  }
  .service-slider__info-inner {
    padding-left: 75px;
  }
  .service-slider__info-count {
    font-size: 32px;
    top: -6px;
  }
  .header-info__col:nth-of-type(4) {
    padding-right: 0;
  }
  .footer .contacts-actions {
    gap: 60px;
  }
  .footer-info__col:nth-child(1) {
    width: 33%;
  }
  .footer-info .contacts-actions {
    flex-wrap: nowrap;
  }

  .suggestions__title {
    margin-bottom: 40px;
  }
  .suggestions__content .suggestion-label {
    margin-bottom: 40px;
  }
  .suggestions__item:first-child .suggestion-label {
    margin-bottom: 100px;
  }
  .suggestions__item:first-child .suggestions__description {
    margin-bottom: 50px;
  }

  .smart-house-section {
    .smart-house-slide-inner {
      .smart-house-article {
        padding: 60px 0 60px 60px;
        margin-bottom: 30px;
      }

      .smart-house__title {
        margin: 0 0 80px 60px;
      }

      .smart-house-links {
        margin-left: 60px;
      }
    }
  }

  .contact-main {
    &.section {
      margin-bottom: 0;
    }
  }

  .contact-panel {
    .contact-list-item {
      flex-direction: column;
    }
  }

  .licens-popup {
    padding: 45px 100px;
  }

  .special-offer {
    .special-offer__header {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    .special-offer__button {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .header__controls-item {
    margin-right: 30px;
  }
  .menu__item {
    margin-right: 33px;
  }
  .menu__item a .icon-dropdown {
    margin-left: 2px;
  }
  .location__title {
    width: 43%;
  }
  .location__description {
    width: 43%;
  }
  .services__title {
    margin-bottom: 20px;
    font-size: 22px;
  }
  .services__icon {
    margin-bottom: 55px;
  }
  .services__icon svg {
    width: 60px;
    height: 60px;
  }
  .services__link {
    width: 50px;
    height: 50px;
  }
  .services__content {
    padding: 15px 15px 81px;
  }
  .services {
    gap: 15px;
  }
}

@media screen and (max-width: 1500px) {
  .service-slider .slick-current-clone-animate .service-slider__img,
  .service-slider .slick-current .service-slider__img {
    width: 42%;
  }
  .service-slider .slick-current-clone-animate .service-slider__img:before,
  .service-slider .slick-current .service-slider__img:before {
    padding-top: 117%;
  }
  .service-slider__info-inner {
    padding-left: 32px;
  }
  .service-slider__info-count {
    font-size: 30px;
    left: -45px;
    padding-left: 50px;
  }
  .service-slider__info-count:before {
    width: 40px;
  }
  .service-slider__info {
    width: 50%;
  }
  .special-offers__content {
    padding: 11% 7%;
  }
  .security-info__nav-item {
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;

  }
  .slick-slide.slick-current .security-info__nav-item {
    padding: 25px 0;
    font-size: 21px;
  }
  .order-security__form {
    width: 60%;
  }
  .app-info__item {
    margin-bottom: 75px;
  }
  .app-info__icon {
    margin-bottom: 30px;
  }
  .app-info__title {
    margin-bottom: 15px;
  }
  .header-info {
    padding: 50px 25px 50px;
  }
  .contacts-phones__item:first-child {
    margin-right: 20px;
  }
  .header__controls-item svg {
    width: 20px;
    height: 20px;
  }
  .header-hamburger span {
    width: 20px;
    margin-bottom: 5px;
  }
  .footer .contacts-actions {
    gap: 30px;
  }
  .footer-info__col:nth-child(1) {
    width: 36%;
  }
  .statistics {
    gap: 70px;
  }
  .app-container {
    max-width: 1136px;
  }
  .advantages__icon svg {
    width: 50px;
    height: 50px;
  }
  .advantages__icon {
    padding: 30px 0;
    margin-top: -30px;
    margin-bottom: 0;
  }
  .advantages__text {
    font-size: 14px;
  }
  .reviews-slider__item {
    padding: 40px;
  }
  .reviews-slider__text {
    font-size: 16px;
    margin-bottom: 40px;
    line-height: 1.6;
  }
  .reviews-slider__date {
    font-size: 14px;
  }
  .reviews-slider__name {
    font-size: 14px;
  }
  .reviews-slider__rating svg {
    margin-right: 5px;
  }


  .smart-house-section {
    .smart-house-slide-inner {
      .smart-house-article {
        padding: 30px 0 30px 30px;
        margin-bottom: 30px;
      }

      .smart-house__title {
        margin: 0 0 40px 30px;
      }

      .smart-house-links {
        margin-left: 30px;
      }
    }
  }

  .managers-section {
    .manager-card {
      flex-direction: column;
      align-items: center;
      padding: 40px 20px;
    }

    .manager-card__img-holder {
      max-width: 300px;
      margin-right: 0;
    }

    .manager-card__name {
      max-width: 100%;
    } 

    .manager-card__position {
      opacity: 1;
      visibility: visible;
    }
  }


}

@media screen and (max-width: 1400px) {
  .licenses-section.section {
    margin-bottom: 290px;
  }
  .licenses-title {
    max-width: 600px;
  }
  .licenses-slider-for {
    width: 41%;
  }
  .licenses-slider-nav {
    width: calc(48% + 60px);
    margin-left: -30px;
  }
  .licenses-nav {
    width: 48%;
  }
  .licenses-slider-nav .slick-slide {
    padding: 0 30px;
  }
  .features-section h2{
    margin-bottom: 60px;
  }

  .subscribe-plan-section{
    h2{
      margin-bottom: 60px;
    }
  }
  .statistics__info-title {
    margin-bottom: 20px;
  }
  .subscribe-plan__list li {
    margin-bottom: 15px;
  }

  .security-info__img {
    width: 57%;
  }
  .security-info__about {
    padding: 60px 25% 80px 0;
  }
  .security-info__col:nth-child(1){
    width: 55%;
  }
  .security-info__col:nth-child(2){
    width: 45%;
  }
  .security-info__text {
    padding-left: 37%;
  }
  .security-cards-section h2 {
    margin-bottom: 60px;
  }
  .card__img {
    width: 33%;
  }
  .security-cards {
    gap: 30px;
  }
  .security-cards__col {
    width: calc(50% - 15px);
  }
  .card__content {
    padding: 8% 103px 20px 7%;
  }
  .offer-slider-wrapper {
    margin-top: 70px;
  }
  .card__title {
    font-size: 21px;
    margin-bottom: 25px;
  }
  .card {
    height: 260px;
  }
  .order-security__form {
    width: 68%;
  }
  .order-security__title {
    width: 29%;
  }
  .main-slider__info, .main-slider-dots__inner {
    max-width: 523px;
  }
  .reviews__arrows {
    margin-left: 40px;
    margin-bottom: 40px;
  }
  .contacts-phones__item a {
    font-size: 14px;
  }
  .contacts-phones__item span {
    font-size: 10px;
  }
  .info-menu__title {
    max-width: 100%;
    padding-bottom: 13px;
    margin-bottom: 30px;
  }
  .header__controls-item {
    margin-right: 20px;
  }
  .header__top {
    padding-bottom: 10px;
  }
  .menu__item {
    margin-right: 25px;
  }
  .contacts-actions {
    margin-bottom: 30px;
  }
  .search-results {
    margin-top: 40px;
    padding-bottom: 40px;
  }
  .search-panel {
    padding: 30px;
  }
  .m-search-input {
    padding-left: 60px;
  }
  h1, .h1 {
    font-size: 47px;
  }
  h2, .h2 {
    font-size: 41px;
  }
  h1, h2 {
    margin-bottom: 50px;
  }
  h3, .h3 {
    font-size: 38px;
  }
  h4, .h4 {
    font-size: 34px;
  }

  .statistics__list {
    gap: 80px 30px;
  }
  .statistics__item {
    width: calc(50% - 15px);
  }
  .statistics__count {
    font-size: 58px;
  }
  .statistics__item:nth-child(2n) .statistics__name {
    margin-top: 70px;
  }
  .statistics__name {
    margin-bottom: 70px;
  }
  .footer .contacts-phones__item span {
    font-size: 12px;
  }
  .footer-info {
    margin-bottom: 40px;
  }
  .footer-payments {
    margin-bottom: 90px;
  }
  .footer__menu-item {
    margin-right: 40px;
  }
  .footer__top {
    padding: 20px 0;
  }
  .footer__logo img {
    width: 140px;
    height: 37px;
  }
  .section-description {
    margin-bottom: 50px;
  }
  .section-heading h2, .app-section h2, .order-security__title h2, .reviews__title h2 {
    margin-bottom: 30px;
  }
  .section-heading {
    margin-bottom: 100px;
  }
  .achievements__logo {
    width: 170px;
    height: 170px;
    margin-bottom: 16px;
  }
  .suggestions__content {
    padding: 35px;
  }

  .blog-general__img {
    margin-bottom: 20px;
  }

  .blog-aside__item {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
  .section {
    margin-bottom: 170px;
  }
  .services__title {
    font-size: 20px;
  }
  .services__icon {
    margin-bottom: 40px;
  }
  .suggestions__item:first-child .suggestions__content {
    padding-bottom: 40px;
  }

  .tariffs-section {
    .tariffs-table-heading {
      margin-right: 40px;
    }

    .tariffs-table-fee {
      margin-right: 40px;
    }
  }

  .search-section {
    .search-panel {
      width: 100%;
      opacity: 1;
      visibility: visible;
      height: 100%;
      max-height: 100%;
      padding: 0;
      pointer-events: auto;
    }
  }

  .article-section {
    .article {
      max-width: 768px;
      margin-right: 100px;
    }
  }

  .career-main-section {
    &.section {
      margin-bottom: 40px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .subscribe-plan__price {
    font-size: 32px;
  }
  .special-offers__title {
    margin-bottom: 40px;
  }
  .general-about__text {
    column-gap: 80px;
  }
  .suggestions__content {
    padding: 27px;
  }
  .suggestions__item:first-child .suggestion-label {
    margin-bottom: 75px;
  }
  .suggestions__item:first-child .suggestions__title {
    margin-bottom: 20px;
  }
  .suggestions__item:first-child .suggestions__description {
    margin-bottom: 40px;
  }
  .footer .contacts-phones__item {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer .contacts-actions {
    flex-direction: column;
  }
  .footer-info__col:nth-child(1) {
    width: 26%;
  }
  .footer-info__col:nth-child(4) {
    width: 23%;
  }
  .footer-info__col:nth-child(3) {
    width: 19%;
  }
  .footer-info__col:nth-child(2) {
    width: 17%;
  }
  .footer-payments {
    margin-top: -100px;
  }

  .article-section {
    .article-section-inner {
      flex-direction: column;

      .article {
        margin: 0 0 40px 0;
        max-width: 100%;
      }

      .blog-aside  {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .blog-aside__item {
        padding: 40px;
      }

      blockquote {
        &::after {
          right: -45px;
        }
      }
    }
  }
}

@media screen and (max-width: 1270px) {
  .service-arrows {
    margin-top: 25px;
    margin-left: 0;
  }
  .security-info__about {
    padding: 60px 25% 40px 0;
  }
  .security-info__nav-item {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .slick-slide.slick-current .security-info__nav-item {
    padding: 15px 0;
    font-size: 16px;
  }
  .menu {
    visibility: hidden;
    opacity: 0;
    height: 0;
  }
  .menu__item:hover a {
    color: #101820;
  }
  .menu__item:hover a .icon-dropdown svg path {
    fill: #757D85;
  }
  .menu__item.parent-menu.dropdown-active > a {
    color: #0047bb;
  }
  .menu__item.parent-menu.dropdown-active > a .icon-dropdown svg path {
    fill: #0047bb;
  }
  .menu__item.parent-menu.dropdown-active > a .icon-dropdown {
    transform: rotate(180deg);
  }
  .menu__item.parent-menu.dropdown-active .sub-menu a {
    color: #101820;
  }
  .menu__item:hover a .icon-dropdown {
    transform: rotate(0);
  }
  .header-info-visible .menu {
    visibility: visible;
    opacity: 1;
    height: auto;
  }

  .header-info {
    flex-wrap: wrap;
    padding: 24px;
    gap: 20px;
    justify-content: space-between;
  }
  .menu {
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
    width: 23%;
  }

  .header-info__col:nth-of-type(1),
  .header-info__col:nth-of-type(2),
  .header-info__col:nth-of-type(3) {
    width: 18%;
  }

  .header-info__col {
    flex: 0 1 auto;
  }
  .header-info__col:nth-of-type(4) {
    flex: 0 1 auto;
    padding-left: 0;
    width: 100%;
  }
  .menu__item {
    margin-right: 0;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .menu__item a {
    font-size: 14px;
  }
  .sub-menu {
    visibility: visible;
    position: static;
    opacity: 1;
    box-shadow: none;
    display: none;
    transition: none;
    padding: 15px 0 20px 0;

  }
  .sub-menu__item {
    margin-bottom: 15px;
  }
  .header .all-categories-link {
    font-size: 12px;
  }
  .menu__item:hover .sub-menu {
    margin-top: 0;
  }
  .sub-menu__item a {
    white-space: normal;
    font-size: 12px;
  }
  .info-menu__title {
    padding-bottom: 15px;
    margin-bottom: 25px;
  }
  .header-info .contacts-actions__address span {
    display: block;
  }
  .contacts-content__flex {
    display: flex;
    justify-content: space-between;
  }
  .header-info .contacts-phones__item {
    width: 190px;
  }
  .header-info .contacts-phones__item a {
    font-size: 16px;
  }
  .header-info .contacts-phones__item span {
    font-size: 12px;
  }
  .header-info .social-menu {
    margin-bottom: 17px;
  }
  .header-info .contacts-phones {
    width: 46%;
    justify-content: space-between;
  }

}

@media screen and (max-width: 1200px) {
  .licenses-section.section {
    margin-bottom: 240px;
  }
  .licenses-title {
    max-width: 550px;
  }
  .licenses-nav {
    margin-top: 85px;
  }
  .licenses-slider-for .licenses-slider__title {
    height: 115px;
  }
  .licenses-slider-for .licenses-slider__title span {
    margin-top: -25px;
    font-size: 21px;
  }
  .features__icon {
    margin: auto auto 30px;
  }
  .features__text {
    font-size: 14px;
    max-width: 180px;
  }
  .subscribe-plan {
    gap: 30px;
  }
  .subscribe-plan__item {
    width: calc(33.333% - 15px);
  }
  .statistics_default .statistics__item:first-child {
    margin-top: 126px;
  }
  .statistics_default .statistics__item:nth-child(2n):not(:nth-child(2)) {
    margin-top: -126px;
  }
  .statistics_default .statistics__img {
    margin-bottom: 30px;
  }
  .security-info__title {
    margin-bottom: 40px;
  }
  .order-security__container {
    flex-direction: column;
  }
  .order-security__title {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }
  .order-security__form {
    width: 100%;
  }
  .card {
    height: 230px;
  }

  .card__title {
    font-size: 19px;
  }
  .card__description {
    font-size: 14px;
  }
  .card__link {
    width: 50px;
    height: 50px;
  }
  .card__title {
    margin-bottom: 14px;
  }
  .offer-slider-wrapper {
    margin-top: 0;
  }
  .faq__header {
    padding: 25px 0;
  }
  .faq__title {
    width: 30%;
  }
  .faq__content {
    width: 60%;
  }
  .img-info__content {
    padding: 19% 0 19% 20%;
  }
  .header-info__col:nth-of-type(1),
  .header-info__col:nth-of-type(2),
  .header-info__col:nth-of-type(3) {
    width: 22%;
  }
  .services-section.section {
    margin-bottom: 170px;
  }
  .search-panel {
    width: 568px;
  }
  .services__item {
    padding: 0 15px;
    height: 100%;
  }
  .services__content {
    width: 300px;
  }
  .services .slick-track {
    display: flex !important;
  }

  .services .slick-slide {
    height: auto;
  }
  .services .slick-slide > div {
    height: 100%;
  }
  .services .slick-track {
    margin: 0 -15px;
  }
  .services .slick-dots {
    bottom: -45px;
  }

  .services__title {
    font-size: 23px;
    margin-bottom: 30px;
  }
  .services__icon svg {
    width: 70px;
    height: 70px;
  }
  .services__icon {
    margin-bottom: 60px;
  }
  .services__link {
    right: 30px;
  }
  .advantages-section.section {
    overflow: hidden;
    margin: 0;
    padding-bottom: 130px;
    padding-top: 40px;
  }

  .advantages {
    width: calc(100% + 30px);
  }
  .advantages__item {
    padding: 0 30px;
  }
  .advantages .slick-track {
    margin: 0 -30px;
  }
  .advantages .slick-list {
    padding: 30px;
    margin: -30px;
  }
  .section {
    margin-bottom: 130px;
  }
  .main-slider__info, .main-slider-dots__inner {
    max-width: 500px;
  }
  .gray-section {
    padding-top: 90px;
  }
  .statistics__title .secondary-button {
    display: none;
  }
  .centered-buttons a {
    margin-right: 30px;
  }
  .blog-aside {
    display: none;
  
    &--horizont {
      display: flex;
    }
  }

  .statistics__count {
    font-size: 43px;
  }
  .statistics__content {
    width: 63%;
  }
  .reviews-slider__item {
    padding: 27px;
  }
  .reviews__arrows {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .statistics__description {
    line-height: 18px;
    font-size: 16px;
    width: 97px;
  }
  .reviews-slider__header {
    margin-bottom: 28px;
  }
  .reviews-slider__text {
    margin-bottom: 30px;
  }
  .suggestions__item:first-child .suggestion-label {
    margin-bottom: 40px;
  }
  .suggestions__item:first-child .suggestions__description {
    margin-bottom: 14px;
  }
  .location__map {
    height: 558px;
  }
  .location__email {
    margin-bottom: 20px;
  }
  .location h2 {
    margin-bottom: 30px;
  }
  .location__title {
    top: 72px;
  }
  .location__description {
    margin-top: 275px;
  }
  .connection-slider__logo {
    width: 180px;
    height: 180px;
  }
  .statistics__img:after {
    padding-top: 130%;
  }
  .general-about__text {
    margin-bottom: 40px;
  }
  h1, .h1 {
    font-size: 41px;
  }
  h2, .h2 {
    font-size: 36px;
  }
  h3, .h3 {
    font-size: 34px;
  }
  h4, .h4 {
    font-size: 31px;
  }
  h5, .h5 {
    font-size: 28px;
  }

  .tariffs-section {
    .tariffs-table {
      border: none;
    }

    .tariffs-table-coll {
      max-width: 100%;
      width: 100%;
      border: none;
    }

    .tariffs-table-condition, .tariffs-table-fee {
      width: 50%;
      margin: 0 auto;
    }

    .tariffs-table-condition {
      .tariffs-condition {
        margin-bottom: 20px;
        justify-content: center;
      }
    }

    .tariffs-table-fee {
      padding: 30px 0;

      .tariffs-fee {
        margin-bottom: 26px;
      }

      .tariffs-fee__text {
        font-size: 12px;
      }

      .tariffs-fee__price {
        font-size: 16px;
      }
    }
  
    .tariffs-table-heading {
      margin-right: 0;
      padding: 0;

      .tariffs-table__title{
        padding: 16px 20px;
        margin: 0 auto 0 0;

        &--marged {
          margin-bottom: 17px;
        }
      }

      .tariffs-table__link {
        padding: 0 16px 20px 16px;
      }
    }

    .tariffs-table-button {
      display: flex;
      justify-content: center;
    }
    
    .tariffs-heading {
      &-inner {
        flex-direction: column;
        align-items: flex-start;
      }
  
      .tariffs__title {
        margin-bottom: 40px;
      }

      .secondary-button {
        margin: 0 auto;
      }
    }

    .tariffs-table-row {
      flex-wrap: wrap;
      border: none;
      padding-bottom: 40px;
    }
  } 

  .blog-section {
    .blog-main__item {
      .blog-main__img {
        width: 60%;
      }
    }
  }

  .blog-section {
    .blog-articles {
      .articles__item-holder {
        width: 100%;
      }
    }

    .blog-articles {
      flex-direction: column;

      .articles__item {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        &:nth-child(2n+1) {
          margin-right: 0;
        }
      }
    }

  }

  .career-section {
    .career-content {
      flex-direction: column;
    }

    .career-heading {
      margin: 0 0 40px 0;
    }
    
    .career-text {
      max-width: 600px;
    }

    .breadcrumbs {
      padding: 20px 0 60px;
    }

    .career-contetn-side {
      padding-bottom: 70px;
    }
  }

  .contact-panel {
    .contact-list-item {
      width: 50%;
    }
  }

  .erorr-section {
    margin: -100px 0 0;
    padding: 160px 0 125px;

    .erorr-section-decor {
      max-width: 393px;
      max-height: 500px;
    }
    .erorr-content__title {
      font-size: 370px;
    }
  }

  .managers-section {
    .managers-heading {
      flex-direction: column;

      .managers__title {
        margin: 0 0 20px 0;
      }

      .managers-heading-group {
        justify-content: center;
        width: 100%;
      }
    }
  }

  .special-offer {
    .special-offer__info {
      padding-left: 60px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .payment__col {
    width: calc(50% - 20px);
  }
  .subscribe-plan {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .subscribe-plan .slick-track {
    display: flex !important;
  }
  .subscribe-plan .slick-slide{
    padding: 0 15px;
    height: auto;
    > div{
      height: 100%;
    }
  }
  .subscribe-plan__item {
    height: 100%;
  }
  .special-offers__content {
    padding: 10% 7%;
  }

  .consultation-section .secondary-button {
    padding-left: 40px;
    padding-right: 40px;
  }
  .order-form__privacy > span {
    max-width: 100%;
  }
  .security-info__nav-item {
    padding-left: 10px;
  }
  .security-info__about {
    padding: 60px 9% 40px 0;
  }
  .security-info__img{
    display: none;
  }
  .security-info__text {
    padding-left: 15%;
  }
  .order-form__button .main-button {
    padding-left: 50px;
    padding-right: 50px;
  }
  .footer-info__col:nth-child(4),
  .footer-info__col:nth-child(2),
  .footer-info__col:nth-child(3) {
    width: 25%;
  }

  .footer-info {
    flex-wrap: wrap;
    gap: 40px 0;
    margin-bottom: 80px;
  }
  .footer-info__col:nth-child(1) {
    width: 56%;
    order: 4;
  }
  .footer .contacts-actions {
    flex-direction: row;
  }
  .footer .contacts-phones__item {
    flex-direction: row;
  }
  .footer-payments {
    flex-direction: column;
    margin: 0;
    order: 4;
  }
  .footer-payments__flex {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }
  .footer-payments {
    width: 260px;
  }
  .services__link {
    opacity: 1;
  }
  .services__content {
    width: 280px;
  }
  .heading-flex {
    margin-bottom: 40px;
  }

  .study-section {
    .study-section-content {
      flex-direction: column;
      align-items: center;
    }

    .study-col {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
    }
  }

  .career-main-section {
    .career-main-content-inner {
      flex-direction: column;
      align-items: center;
    }
  }

  .invitation-section {
    .invitation-form-side {
      width: 100%;
      padding: 0;
      margin-bottom: 30px;

      &:last-child {
        border: none;
        padding: 0;
      }
    }

    .invitation-form {
      flex-direction: column;
    }
  }

  .table-section {
    .table__title {
      font-size: 28px;
    }
  }

  .licenses-section {
    .licenses-section-list-item {
      width: 50%;
    }
  }

  .licens-popup {
    padding: 25px 60px;
  }

  .special-offer {

    .special-offer__header {
      margin-bottom: 30px;

      .special-offer__button {
        font-size: 12px;
      }
    }

    .special-offer__img {
      width: 100%;
      margin-bottom: 40px;
    }

    .special-offer__info {
      width: 100%;
      padding: 0;

      .special-offer__button {
        margin-top: 60px;
      }
    }

    .special-offer__label {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 991px) {
  .service-slider__item {
    flex-direction: column;
    padding-top: 30px;
    height: 100%;
    justify-content: space-between;
  }
  .service-slider__info {
    width: 100%;
    margin-bottom: 50px;
    display: block;
    height: auto;
    opacity: 0;
    flex: 1 auto;
  }
  .service-slider__img {
    width: 100%;
    margin-bottom: 3%;
  }
  .service-slider .slick-current-clone-animate .service-slider__item,
  .service-slider .slick-current .service-slider__item {
    width: 100% !important;
    margin-left: 0;
  }

  .service-slider .slick-current-clone-animate .service-slider__img,
  .service-slider .slick-current .service-slider__img {
    width: 100%;
    margin-bottom: 0;
  }
  .service-slider__img:before {
    padding-top: 88%;
  }
  .service-slider .slick-current-clone-animate .service-slider__img:before,
  .service-slider .slick-current .service-slider__img:before {
    padding-top: 94%;
  }
  .service-slider .slick-current-clone-animate .service-slider__info,
  .service-slider .slick-current .service-slider__info {
    opacity: 1;
    align-items: flex-end;
  }

  .service-slider .slick-track {
    align-items: normal;
  }
  .service-slider .slick-slide {
    > div{
      height: 100%;
    }
    height: auto;
  }
  .licenses-section.section {
    margin-bottom: 130px;
  }
  .licenses-title {
    position: static;
    max-width: 600px;
    margin: auto auto 60px;
    h2{
      text-align: center;
    }
    .section-description{
      max-width: 544px;
      margin: 0 auto;
      text-align: center;
    }
  }
  .licenses-slider-wrapper {
    flex-direction: column-reverse;
  }
  .licenses-slider-nav {
    width: 670px;
    margin: 0 auto;
  }
  .licenses-slider-for {
    width: 100%;
    margin: 0 auto;
    transform: none;
  }
  .licenses-slider-for .licenses-slider__img {
    width: 44%;
  }
  .licenses-slider-for .slick-slide {
    padding-top: 0;
  }
  .licenses-nav {
    margin-top: 0;
    width: 100%;
    justify-content: center;
  }

  .licenses-arrows {
    display: flex;
    align-items: center;
    position: absolute;
    top: 16%;
    left: 50%;
    transform: translateX(-50%);
    width: calc(48% + 83px);
    justify-content: space-between;
  }
  .features {
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px 30px;
  }
  .features__item{
    width: 24%;
  }
  .features__icon {
    margin: auto auto 20px;
  }
  .features-section {
    padding: 90px 0;
  }
  .top-info__title {
    padding: 60px 0 0;
  }
  .top-info__title h1 {
    max-width: 69%;
    margin: auto auto 40px;
  }
  .location__list {
    gap: 10px;
  }
  .location__list-item {
    margin-bottom: 16px;
    padding-left: 27px;
    font-size: 14px;
  }
  .location__list-item:before {
    width: 20px;
    height: 20px;
  }

  .order-form__footer {
    flex-direction: column;
  }
  .order-form__button {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .order-form__privacy {
    text-align: center;
  }
  .slick-slide.slick-current .security-info__nav-item {
    padding-left: 0;
  }
  .card {
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
  }
  .card__content {
    padding: 30px 103px 30px 30px;
  }
  .card__img {
    width: 100%;
    height: 200px;
  }
  .faq {
    flex-direction: column;
    align-items: center;
  }
  .faq__title {
    width: 100%;
    align-items: center;
  }
  .faq__content {
    width: 100%;
    margin-bottom: 60px;
  }
  body:not(.home) .main {
    padding-top: 100px;
  }
  .breadcrumbs {
    padding: 20px 0 25px;
  }
  .top-info__col_image {
    width: 100%;
    height: 300px;
  }

  .top-info {
    margin-top: 0;
    flex-direction: column-reverse;
  }
  .top-info__col_title {
    width: 100%;
  }
  .header.fixed {
    top: 0;
  }
  .header-top {
    position: relative;
  }
  .footer-info__col:nth-child(4), .footer-info__col:nth-child(2), .footer-info__col:nth-child(3) {
    width: 30%;
  }
  .footer-info__col:nth-child(1) {
    width: 59%;
  }
  .footer .contacts-actions {
    gap: 20px;
  }
  .footer__menu-item {
    margin-right: 30px;
  }
  .footer__bottom {
    flex-wrap: wrap;
  }
  .footer__copy {
    width: 100%;
    order: 3;
    flex: 1 0 auto;
    margin-top: 30px;
    text-align: center;
  }
  .header {
    position: fixed;
    top: 0;
    width: calc(100% + 30px);
    margin-left: -15px;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.1));
  }

  .header__logo img {
    width: 122px;
    height: 31px;
  }
  .actions-select__text {
    font-size: 14px;
  }
  .header-top__inner {
    background: #fff;
    height: auto;
  }
  .header-actions__left .phone {
    margin-left: auto;
    font-size: 14px;
  }
  .header-actions, .header-actions__left {
    width: 100%;
  }
  .header-actions__right {
    display: none;
  }
  .actions-select {
    margin-right: 0;
  }
  .header__top {
    padding-bottom: 0;
  }

  .header__inner {
    height: auto;
    box-shadow: none;
    padding: 15px 0;
    border-radius: 0;
  }
  .header__flex {
    margin-bottom: 15px;
  }
  .header.fixed .header-info, .header.fixed .search-panel {
    max-height: calc(var(--vh, 1vh) * 100 - 97px);
  }
  .search-panel, .header-info {
    max-height: calc(var(--vh, 1vh) * 100 - 97px);
  }
  .header-info, .search-panel {
    margin-top: 0;
    box-shadow: none;
    border-radius: 0;
    padding: 24px 15px;
    transform: translate(0px, 0px) !important;
  }
  .header-info:after {
    content: "";
    display: block;
    width: calc(100% - 30px);
    height: 1px;
    background-color: rgba(117, 125, 133, .4);
    position: absolute;
    left: 15px;
    top: 0;
  }
  .info-menu__title {
    border-bottom: 1px solid rgba(117, 125, 133, .4);
  }
  .header-info__col:nth-of-type(4) {
    width: 62%;
  }
  .menu {
    width: 31%;
  }
  .header-info__col:nth-of-type(1), .header-info__col:nth-of-type(2), .header-info__col:nth-of-type(3) {
    width: 27%;
  }
  .header-info .contacts-phones{
    justify-content: flex-start;
  }
  .contacts-phones {
    flex-direction: column;
    justify-content: flex-start;
  }
  .contacts-phones__item:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .contacts-actions {
    margin-bottom: 0;
    flex-direction: column;
  }
  .contacts-actions-mob {
    display: none;
  }
  .location__map {
    height: 480px;
  }
  .location__description, .location__title {
    width: 45%;
  }
  .location__title {
    top: 45px;
  }
  .location__description {
    margin-top: 238px;
  }
  .search-panel {
    width: calc(100% - 30px);
    left: 15px;
    right: auto;
  }
  .advantages__item {
    padding: 0 15px;
  }
  .advantages .slick-track {
    margin: 0 -15px;
  }
  .advantages__icon {
    padding-bottom: 20px;
  }
  .pre-title {
    font-size: 14px;
  }
  .main-slider__info h1 {
    margin-bottom: 40px;
  }
  .main-slider-dots__inner, .main-slider__info {
    max-width: 400px;
  }
  .statistics {
    flex-direction: column;
    gap: 0;
  }
  .statistics__title h2 {
    margin-bottom: 60px;
  }
  .statistics__title {
    position: relative;
    top: 0;
    width: 100%;
    text-align: center;
    align-items: center;
  }
  .statistics__content {
    width: 100%;
  }
  .statistics__list {
    gap: 70px 30px;
  }
  .statistics__name {
    margin-bottom: 40px;
  }
  .statistics__item:nth-child(2n) .statistics__name {
    margin-top: 40px;
  }
  .services__icon svg {
    width: 60px;
    height: 60px;
  }
  .services__icon {
    margin-bottom: 50px;
  }
  .services__title {
    margin-bottom: 20px;
  }
  .statistics__count {
    font-size: 50px;
  }
  h1, .h1 {
    font-size: 37px;
  }
  h2, .h2 {
    font-size: 34px;
  }
  h3, .h3 {
    font-size: 32px;
  }
  h4, .h4 {
    font-size: 29px;
  }
  h5, .h5{
    font-size: 25px;
  }
  .reviews {
    width: 100%;
    flex-direction: column;
  }
  .reviews__content {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .reviews__title {
    width: 100%;
    margin-top: 0;
    text-align: center;
    align-items: center;
  }
  .reviews__buttons {
    width: 100%;
    justify-content: center;
    margin-top: 200px;
  }
  .suggestions {
    display: block;
    margin-bottom: 85px;
  }
  .suggestions__description {
    display: none;
  }
  .suggestions__item {
    display: flex !important;
    height: 100%;
  }
  .suggestions__item .suggestions__img,
  .suggestions__item .suggestions__img:first-child {
    width: 35%;
  }

  .suggestions__item .suggestions__title,
  .suggestions__item:first-child .suggestions__title {
    margin-bottom: 40px;
  }
  .suggestions__item .suggestions__content,
  .suggestions__item:first-child .suggestions__content {
    padding-bottom: 40px;
    padding-top: 35px;
    display: flex;
    justify-content: space-between;
  }
  .suggestions__item .suggestion-label,
  .suggestions__item:first-child .suggestion-label {
    margin-bottom: 40px;
  }

  .suggestions .slick-track {
    display: flex !important;
  }
  .suggestions .slick-slide > div {
    height: 100%;
  }
  .suggestions .slick-slide {
    height: auto;
  }
  .blog-general__col:nth-child(3) {
    display: none;
  }
  .blog-section .blog-flex {
    gap: 30px;
    border-bottom: 1px solid #D0D4D6;
    margin-bottom: 40px;
    padding-bottom: 50px;
  }
  .app-links__qr {
    display: none;
  }
  .app-links__item:first-child {
    margin-right: 30px;
  }
  .app-info {
    flex-direction: column;
  }
  .app-info__left {
    width: 100%;
    order: 2;
  }
  .app-info__middle {
    width: 239px;
    order: 1;
    margin-bottom: 50px;
  }
  .app-info__right {
    width: 100%;
    order: 3;
  }
  .app-info__item {
    display: flex;
  }
  .app-info__icon {
    margin-bottom: 0;
    margin-right: 22px;
    flex-shrink: 0;
  }
  .app-info__item, .app-info__left {
    margin-bottom: 45px;
  }
  .app-info__title {
    margin-bottom: 10px;
  }

  .blog-aside {
    border: none;

    &--horizont {
      flex-direction: column;
    }

    .blog-aside__item {
      border-bottom: 1px solid #d0d4d6;
      margin: 0 0 30px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .erorr-section {
    margin: -100px 0 0;
    padding: 190px 0 125px;

    .erorr-section-decor {
      max-width: 262px;
      max-height: 334px;
    }
    .erorr-content__title {
      font-size: 270px;
    }

    .erorr-message__title {
      margin-bottom: 40px;
    }

    .erorr-message__text {
      margin-bottom: 0;

      &:last-child {
        margin-bottom: 60px;
      }
    }
  }

  .managers-section {
    .manager-card-holder {
      width: 50%; 
   }
  }

  .licens-popup {
    transform: translate3d(0, 0, 0);
    top: 110px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    padding: 25px 40px;

    .licens-popup__close {
      right: 10px;
      top: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .payment__col {
    width: 100%;
  }
  .service-slider__info-count {
    font-size: 26px;
    left: -20px;
    padding-left: 40px;
    top: 6px;
  }
  .service-slider__info-count:before {
    width: 30px;
  }
  .service-slider__info{
    width: 475px;
    margin-bottom: 40px;
  }
  .service-slider__info-inner {
    padding-left: 40px;
  }
  .service-slider__info-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .service-slider-section h2{
    margin-bottom: 60px;
  }
  .licenses-slider-nav {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .licenses-slider-for .licenses-slider__img {
    width: 57%;
  }
  .licenses-arrows {
    width: calc(62% + 83px);
  }
  .licenses-slider-nav .slick-slide {
    padding: 0 20px;
  }
  .features__item {
    width: auto;
  }
  .offer-slider__img {
    margin-bottom: 30px;
  }
  .location_region h2{
    margin-bottom: 60px;
    text-align: center;
    width: 100%;
  }
  .location__about {
    margin-bottom: 40px;
  }
  .location__list-item {
    text-align: left;
  }
  .order-form__row {
    flex-direction: column;
  }
  .order-form__col {
    width: 100%;
  }
  .order-form__row {
    gap: 40px;
    margin-bottom: 40px;
  }

  .order-form__privacy {
    text-align: left;
  }
  .img-info__col.img-info__col_image:after {
    content: "";
    display: block;
    padding-top: 52%;
  }
  .card__img {
    height: 157px;
  }
  .card__info {
    max-width: 100%;
  }
  .security-cards {
    gap: 20px;
  }
  .security-info__about {
    padding: 0;
  }
  .security-cards__col {
    width: calc(50% - 10px);
  }
  .security-cards .card__link{
    display: none;
  }
  .card__content {
    padding: 30px 15px;
  }
  .security-info {
    flex-direction: column;
  }
  .security-info__col:first-child {
    width: 100%;
  }
  .security-info__col:nth-child(2) {
    width: 100%;
  }
  .security-info__text {
    padding: 0;
  }
  .security-info__item {
    padding: 60px 25px;
  }
  .slick-slide.slick-current .security-info__nav-item {
    padding: 30px 0;
    font-size: 18px;
  }
  .security-info__title {
    margin-bottom: 50px;
  }
  .img-info__image {
    width: calc(100% + 30px);
    right: -15px;
  }
  .img-info__col.img-info__col_content {
    margin-left: -15px;
    width: calc(100% + 30px);
  }
  .img-info {
    flex-direction: column;
  }
  .img-info__col_image {
    width: 100%;
  }
  .img-info__content {
    padding: 60px 15px;
  }
  .img-info__text {
    align-items: center;
    width: 100%;
    text-align: center;
  }
  .header-info, .header.fixed .header-info,
  .search-panel, .header.fixed .search-panel {
    height: calc(var(--vh, 1vh) * 100 - 97px);
    display: block !important;
  }
  .header-info, .search-panel {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  .header-info-visible .header-info,
  .search-panel-visible .search-panel {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .connection-slider__item {
    margin: 10px 38px;
  }
  .connection-slider__logo {
    width: 120px;
    height: 120px;
  }
  .general-about__text {
    column-count: 1;
  }
  .advantages-section.section {
    padding-bottom: 120px;
  }
  .location__title {
    position: static;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    h2 {
      margin-bottom: 60px;
    }
  }
  .location__description, .location__title {
    width: 100%;
  }
  .location__map {
    width: 100%;
  }
  .location__flex {
    flex-direction: column-reverse;
  }
  .location__description {
    margin-top: 0;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .location__description h4 {
    display: none;
  }
  .location__address {
    width: 100%;
  }
  .location__map {
    height: auto;
  }
  .location__map:after {
    content: "";
    display: block;
    padding-top: 70%;
  }
  .achievements__logo {
    width: 140px;
    height: 140px;
    margin-bottom: 12px;
  }
  .section {
    margin-bottom: 120px;
  }
  .j-video .video-js:after {
    padding-top: 54%;
  }
  .services__title {
    font-size: 21px;
  }
  .statistics__count {
    font-size: 41px;
  }
  .statistics__list {
    gap: 50px 30px;
  }
  .statistics__name {
    margin-bottom: 40px;
  }
  .statistics__item:nth-child(2n) .statistics__name {
    margin-top: 40px;
  }

  .statistics__description {
    line-height: 16px;
    font-size: 14px;
    width: 85px;
  }
  h1, .h1 {
    font-size: 34px;
  }
  h2, .h2 {
    font-size: 28px;
  }
  h3, .h3 {
    font-size: 26px;
  }
  h4, .h4 {
    font-size: 24px;
  }
  h5, .h5 {
    font-size: 22px;
  }
  h6, .h6 {
    font-size: 21px;
  }
  .slick-dots {
    bottom: -35px;
  }


  .menu {
    width: 100%;
  }
  .header-info {
    gap: 0;
  }
  .header-info__col:nth-of-type(1), .header-info__col:nth-of-type(2), .header-info__col:nth-of-type(3) {
    width: 100%;
  }

  .header-info__col:nth-of-type(4) {
    width: 100%;
  }
  .info-menu__title {
    padding-bottom: 20px;
    padding-top: 23px;
    margin: 0;
  }
  .header-info__col:nth-of-type(1) .info-menu__title {
    border-top: 1px solid rgba(117, 125, 133, .4);
  }

  .info-content {
    display: none;
    padding-top: 20px;
  }
  .info-menu__header {
    position: relative;
  }
  .info-menu__icon {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 24px;
    height: 24px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .info-menu__icon:before, .info-menu__icon:after {
    content: "";
    display: block;
    background: #757D85;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 2px;
    transition: .2s linear;
  }
  .info-menu__icon:after {
    transform: rotate(-90deg);
    margin-top: -1px;
  }
  .header-info__col.active .info-menu__icon:after,
  .footer-info__col.active .info-menu__icon:after {
    opacity: 0;
  }

  .footer-info {
    display: block;
    margin-bottom: 45px;
  }
  .footer-info__col:nth-child(1) {
    width: 100%;
  }
  .footer-info__col:nth-child(2), .footer-info__col:nth-child(3), .footer-info__col:nth-child(4) {
    width: 100%;
  }
  .footer .info-menu__title {
    margin-bottom: 0;
  }
  .footer .info-content {
    padding-bottom: 20px;
  }
  .footer .contacts-phones__item a {
    font-size: 16px;
  }
  .footer .contacts-phones__item span {
    font-size: 14px;
  }
  .footer .contacts-actions {
    gap: 60px;
    border-bottom: none;
    margin-bottom: 40px;
    padding-bottom: 0;
  }
  .footer__top {
    justify-content: center;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(117, 125, 133, .4);
  }
  .footer__menu {
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 20px;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(117,125,133,.4);
  }
  .footer__menu-item {
    margin-right: 0;
  }
  .footer__menu-item a {
    font-size: 14px;
  }
  .footer-info__col:nth-child(4) {
    margin-bottom: 30px;
  }
  .footer-info__col:nth-child(5) {
    display: block;
  }
  .footer-about {
    margin-bottom: 40px;
  }
  .footer-about__title {
    display: block;
    font-size: 14px;
    font-family: $secondaryFont;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .footer-about__description {
    display: block;
    font-size: 14px;
  }
  .footer-info .social-menu {
    justify-content: center;
    margin-bottom: 50px;
  }
  .footer-info .social-menu li {
    margin-right: 72px;
  }
  .footer-info .social-menu li:last-child{
    margin-right: 0;
  }
  .footer-info .social-menu li svg {
    width: 32px;
    height: 32px;
  }
  .footer__privacy {
    text-align: left;
  }
  .footer__copy {
    text-align: left;
    margin-top: 15px;
  }
  .footer-payments__flex {
    margin-bottom: 0;
    margin-right: 20px;
    width: 260px;
  }
  .footer-payments {
    width: 100%;
    justify-content: center;
    flex-direction: row;
  }
  
  .smart-house-section {
    .smart-house-slide-inner {
      flex-direction: column;
    }
  }

  .smart-house-section {
    .smart-house-slide-inner {
      .smart-house-article {
        padding: 60px 24px;
        margin: 0 16px 110px 16px;
      }

      .smart-house__title {
        margin: 40px 30px;
      }

      .smart-house-links {
        border-top: 1px solid rgba(117, 125, 133, .3);
        padding-top: 22px;
      }
    }

    .smart-house-slider-controls {
      transform: translateX(50%);
      right: 50%;
      bottom: 12vw;
    }
  }

  .blog-section {
    .blog-main__item {
      flex-direction: column;

      .blog-main__img {
        width: 100%;
        min-height: 300px;
      }

      .blog-main__content {
        padding-top: 30px;
      }
    }
  }

  .blog-section {
    .blog-articles  {
      &--bigger {
        margin-bottom: 40px;
      }

      .article__content {
        padding: 30px 25px;
      }

      .articles__item {
        flex-direction: column;

        .article__img {
          width: 100%;
          min-height: 300px;
        }
      }

      .articles__item--bigger {
        .article__title {
          font-size: 18px;
        }
      }
    }
  }

  .erorr-section {
    .erorr-section-decor {
      max-width: 393px;
      max-height: 500px;
    }
    .erorr-content__title {
      font-size: 270px;
    }

    .erorr-message {
      align-items: center;
      text-align: center;
      flex-direction: column;
      text-shadow: 1px 1px 2px #000;
    }

    .erorr-message-side {
      &:first-child {
        max-width: 100%;
      }
    }
  }

  .сertificate-section {
    .certificate-section-inner {
      flex-direction: column-reverse;
      margin: 0;
    }

    .certificate-content {
      padding: 60px 0 20px;
    }

    .certificate-image-holder {
      .certificate__img {
        margin: 0;
      }
    }
    
  }

  .managers-section {
    .managers-heading {
      .managers-heading-group {
        flex-direction: column;
  
        .managers-heading-field  {
          max-width: 100%;
          margin: 0 0 20px;
  
          &--small {
            max-width: 100%;
          }
        }
      } 
    }
  }

  .table-section {
    .table__title {
      line-height: 1.15;
    }
  }

  .erorr-section {
    .erorr-content__title {
      position: absolute;
      left: 50%;
      top: 60px;
      transform: translateX(-50%)
    }
  }

  .erorr-section {
    &.section {
      padding: 236px 0 0;
    }

    .erorr-content {
      margin-bottom: 60px;
    }

    .erorr-section-decor {
      display: flex;
      justify-content: center;
      position: static;
      margin: 0 auto;
      transform: none;
    }
  }

  .special-offer-slider-section {
    .offer-slide-content {
      flex-direction: column;
    }

    .offer-slide-side {
      &:first-child {
        margin-bottom: 20px;
      }
    }

    .offer-slide-content {
      padding: 30px 20px;
    }
  }

  .licenses-section {
    .licenses-section-list-item {
      width: 100%;
    }
  }
}

@media screen and (min-width: 769px) {
  .info-content {
    display: flex !important;
  }
}

@media screen and (max-width: 680px) {
  .advantages__item {
    display: flex !important;
    align-items: center;
  }
  .advantages__icon {
    padding-bottom: 30px;
    margin-bottom: -30px;
    margin-right: 20px;
  }
  .reviews__img {
    display: none;
  }
  .reviews-slider {
    width: 100%;
  }
  .reviews-slider__item {
    padding: 60px 27px;
  }
  .reviews-slider__header {
    margin-bottom: 40px;
  }
  .reviews-slider__text {
    margin-bottom: 40px;
  }
  .reviews-slider__date, .reviews-slider__name {
    font-size: 16px;
  }
  .reviews__right {
    width: 100%;
    transform: none;
  }
  .reviews__buttons {
    margin-top: 93px;
  }
  .reviews__arrows {
    display: none;
  }

  .centered-buttons {
    flex-direction: column;
  }
  .reviews__title {
    text-align: left;
    align-items: flex-start;
  }
  .reviews__buttons {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px 20px;
  }
  .centered-buttons a {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .centered-buttons a:last-child {
    margin-bottom: 0;
  }

  .contact-panel {
    .item-col {
      &:first-child {
        margin-right: 15px;
      }
    }
  }

  .erorr-section {
    .erorr-content__title {
      font-size: 170px;
    }
  }
}

@media screen and (max-width: 575px) {
  .payment__inner {
    padding: 20px;
  }
  .payment__icon img {
    width: 30px;
    height: 30px;
  }
  .payment__title {
    font-size: 18px;
  }
  .payment__pretitle {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .payment__list {
    font-size: 14px;
  }
  .service-slider__info{
    width: 184%;
    margin-left: -42%;
  }
  .service-slider__info-count {
    font-size: 18px;
    top: -5px;
  }
  .service-slider__info-title {
    padding-top: 0;
    font-size: 16px;
  }
  .service-slider__info-description {
    font-size: 14px;
  }
  .service-slider__info-inner {
    padding-left: 60px;
  }
  .service-arrows {
    margin-top: 30px;
  }
  .licenses-slider-for .licenses-slider__title:after {
    height: 218%;
  }
  .licenses-slider-for .licenses-slider__title {
    height: 56px;
  }
  .licenses-slider-for .licenses-slider__title span {
    margin-top: -6px;
    font-size: 14px;
  }
  .licenses-arrows {
    width: calc(57% + 83px);
    top: 11%;
  }
  .features {
    gap: 40px 9px;
  }
  .features-section {
    padding: 60px 0;
  }
  .subscribe-plan__name {
    margin-bottom: 5px;
  }
  .features__text {
    max-width: 155px;
  }
  .subscribe-plan__name svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .subscribe-plan__img {
    margin: auto auto 15px;
  }
  .subscribe-plan__content {
    padding: 40px 25px 15px;
  }
  .subscribe-plan__list {
    margin-bottom: 40px;
  }
  .subscribe-plan__list li {
    font-size: 16px;
    padding-left: 30px;
    margin-bottom: 10px;
  }
  .subscribe-plan__list li:before {
    width: 20px;
    height: 20px;
  }
  .subscribe-plan__price {
    font-size: 24px;
  }
  .subscribe-plan__title {
    font-size: 16px;
  }
  .offer-slider__img {
    margin-bottom: 20px;
  }
  .special-offers-link {
    justify-content: center;
  }
  .special-offers__link{
    display: none;
  }
  .special-offers .slick-dots {
    bottom: -40px;
  }
  .special-offers-link {
    margin-top: 75px;
  }
  .special-offers-arrows {
    display: none;
  }
  .special-offers__title {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    max-width: 58%;
  }
  .special-offers__content {
    padding: 16% 10px;
  }
  .special-offers__img {
    width: calc(44% - 10px);
  }
  .top-info-section:not(.has-slider){
    margin-bottom: 60px;
  }
  .top-info__title h1{
    max-width: 100%;
  }
  .statistics_default .statistics__item:first-child {
    margin-top: 0;
  }
  .statistics_default .statistics__item:nth-child(2n):not(:nth-child(2)) {
    margin-top: 0;
  }
  .statistics__info-title {
    margin-bottom: 10px;
  }
  .location__about {
    font-size: 16px;
  }
  .connection-slider {
    margin: -10px 0;
  }
  .connection-slider__item {
    margin: 10px 22px;
  }
  .connection-slider__logo {
    width: 86px;
    height: 86px;
  }
  .breadcrumbs {
    padding: 20px 0 15px;
  }
  .top-info-section.has-slider .top-info__title {
    padding: 60px 0 0;
  }
  .top-info-section h1{
    margin-bottom: 40px;
  }
  .top-info__title .pre-title {
    margin-bottom: 10px;
  }
  .offer-slider-wrapper {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .offer-slider {
    width: 170%;
    margin-left: -35%;
  }
  .offer-slider .offer-slider__info {
    width: 150%;
    margin-left: -25%;
  }
  .offer-arrows{
    display: none;
  }
  .offer-slider__name {
    font-size: 14px;
  }
  .offer-slider__price {
    font-size: 20px;
  }
  .top-info__col_image {
    height: 430px;
  }
  .faq__header svg {
    width: 15px;
    height: 15px;
  }
  .faq__header {
    padding: 20px 0 10px;
  }
  .security-info__nav-item {
    font-size: 14px;
  }
  .security-info__nav {
    margin-bottom: 40px;
  }
  .faq__question {
    font-size: 14px;
  }
  .faq__description {
    font-size: 12px;
  }
  .slick-slide.slick-current .security-info__nav-item {
    font-size: 14px;
  }
  .security-info__item {
    padding: 50px 15px;
  }
  .security-cards {
    gap: 40px;
  }
  .security-cards__col {
    width: 100%;
  }
  .footer-payments {
    flex-direction: column;
  }
  .footer .contacts-actions {
    flex-direction: column;
    gap: 40px;
  }
  .footer .contacts-phones__item {
    flex-direction: column;
  }
  .footer-payments__flex {
    margin-bottom: 20px;
    margin-right: 0;
    width: 100%;
    justify-content: center;
    gap: 60px;
  }
  .main-slider {
    margin-top: 96px;
  }

  .header-info .contacts-actions{
    gap: 20px;
    margin-bottom: 40px;
  }
  .contacts-actions__address {
    margin-bottom: 0;
  }
  .contacts-content__flex {
    flex-direction: column;
  }
  .contacts-phones__item {
    width: 100%;
  }
  .header-info .social-menu {
    margin-bottom: 20px;
  }
  .contacts-actions-mob {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .info-menu .social-menu li svg {
    width: 32px;
    height: 32px;
  }
  .info-menu .social-menu li {
    margin-right: 72px;
    &:last-child{
      margin-right: 0;
    }
  }
  .main-slider__item {
    height: auto !important;
  }
  .main-slider__item:after {
    content: "";
    display: block;
    padding-top: 106%;
  }

  .main-slider__item .l-container {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .header__logo img {
    width: 104px;
    height: 28px;
  }
  .general-about__text {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 40px;
  }
  .main-slider__img_desktop {
    display: none;
  }
  .main-slider__img_mobile {
    display: block;
  }
  .main-slider__info {
    top: 12%;
    right: auto;
    padding: 0 15px;
    left: 50%;
    transform: translateX(-50%);
  }
  .main-slider__info .secondary-button {
    display: none;
  }


  .main-slider-dots {
    bottom: -20px;
  }
  .main-slider-dots__inner {
    right: 0;
  }
  .main-slider-dots__inner .slick-dots li button {
    background: #CED1D6;
    opacity: 0.9;
  }
  .main-slider-dots__inner .slick-dots li.slick-active button {
    background-color: #0047bb;
  }
  .advantages-section.section {
    padding-top: 80px;
  }
  .main-slider-dots__inner, .main-slider__info {
    max-width: 100%;
  }
  .pre-title {
    font-size: 13px;
  }
  .general-about__text {
    max-height: 234px;
  }
  .achievements__logo {
    width: 86px;
    height: 86px;
    margin-bottom: 20px;
  }
  .achievements-section {
    padding: 60px 0;
  }
  .app-links {
    margin-bottom: 60px;
  }
  .app-info__middle {
    margin-bottom: 60px;
  }
  .app-info__item, .app-info__left {
    margin-bottom: 50px;
  }
  .app-links__item:first-child {
    margin-right: 20px;
  }
  .reviews-slider__item {
    padding: 60px 16px;
  }
  .location__menu {
    width: 100%;
  }
  .location__description {
    align-items: flex-start;
    text-align: left;
  }
  .location__map:after {
    padding-top: 87%;
  }
  .info-img:after {
    content: "";
    display: block;
    padding-top: 71%;
  }
  .info-img {
    position: relative;
  }
  .info-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }
  .section-heading {
    margin-bottom: 70px;
  }
  .section-description {
    margin-bottom: 40px;
  }

  .blog-general__col:nth-child(2) {
    display: none;
  }
  .suggestions__item {
    flex-direction: column;
  }
  .suggestions__item .suggestions__img,
  .suggestions__item .suggestions__img:first-child {
    width: 100%;
  }
  .suggestions__buttons {
    gap: 60px 30px;
  }
  .suggestions__item .suggestions__img:after {
    content: "";
    display: block;
    padding-top: 65%;
  }
  .suggestions__item .suggestions__content, .suggestions__item:first-child .suggestions__content {
    padding: 60px 15px;
  }
  .statistics__item {
    width: 100%;
  }
  .main-button, .secondary-button {
    width: 100%;
  }
  .order-form__button {
    width: 100%;

  }
  .services__content {
    width: 226px;
  }
  .services__title {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .services__icon svg {
    width: 40px;
    height: 40px;
  }
  .services__icon {
    margin-bottom: 54px;
  }
  .services__description {
    font-size: 14px;
  }
  .app-section h2, .order-security__title h2, .reviews__title h2, .section-heading h2 {
    margin-bottom: 20px;
  }
  .gray-section {
    padding-top: 60px;
  }
  .j-video .video-js:after {
    padding-top: 69%;
  }
  .services .slick-dots {
    bottom: -40px;
  }
  .services-section.section {
    margin-bottom: 160px;
  }
  .statistics__item:nth-child(2n) {
    flex-direction: column;
  }
  .statistics__item:nth-child(2n) .statistics__name {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .statistics__count {
    font-size: 50px;
  }
  .statistics__description {
    line-height: 21px;
    font-size: 17px;
    width: 104px;
  }
  .order-security {
    padding: 60px 15px;
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .order-form__privacy {
    font-size: 12px;
  }
  h1, .h1 {
    font-size: 30px;
    letter-spacing: 0.04em;
  }
  h2, .h2 {
    font-size: 24px;
    letter-spacing: 0.03em;
  }
  h3, .h3 {
    font-size: 21px;
  }
  h4, .h4 {
    font-size: 20px;
  }
  h5, .h5 {
    font-size: 19px;
  }
  h6, .h6 {
    font-size: 18px;
  }
  .slick-dots li {
    margin-right: 13px;
  }
  .slick-dots li button {
    width: 31px;
  }

  .contact-panel {
    .contact-list-item {
      flex-direction: column;
      align-items: flex-start;
    }
    .item-col {
      &:first-child {
        margin-right: 0;
      }
    }
  }

  .contact-main {
    .contact-heading-inner {
      flex-direction: column;
      align-items: center;
    }

    .contact-heading__title {
      margin-bottom: 40px;
    }
  }
  
  .tariffs-content {
    .l-container {
      padding: 0;
    }

    .tariffs-table-condition {
      padding-left: 15px;
    }

    .tariffs-table-fee {
      padding-right: 15px;
    }

    .tariffs-table-button {
      padding: 0 15px;
    }
  }

  .blog-section {
    &.blog-page {
      .blog-aside {
        margin-bottom: 60px;
      }
    }
    .blog-main {
      margin-bottom: 60px;
    }
  }

  .article-section {
    .article-section-inner {
      h1 {
        font-size: 24px;
      }

      .blog-aside__item {
        padding: 20px 0;
      }
      
      blockquote {
        font-size: 18px;
        padding-right: 15px;

        &::after {
          right: -1px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  
  .contact-panel {
    .contact-list-item {
      width: 100%;
    }
  }

  .career-main-section {
    .career-main-content-inner {
      padding: 40px 0;
    }

    .career-main__title {
      margin-bottom: 30px;
    }
  }

  .managers-section {
    .manager-card-holder {
      width: 100%;
    }
  }

  .table-section {
    .table-section-inner {
      padding: 60px 15px;
    }

    .table__title {
      font-size: 24px;
    }
  }
}

@media (max-width: 425px) {
  .erorr-section {
    .erorr-content__title {
      font-size: 120px;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 575px) and (orientation: portrait) {
  .main-slider__img_desktop {
    display: none;
  }
  .main-slider__img_mobile {
    display: block;
  }
  .main-slider__info {
    top: 24%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .main-slider__info .secondary-button {
    display: none;
  }
  .main-slider-dots {
    bottom: 20px;
  }
  .main-slider-dots__inner {
    right: 0;
  }
  .main-slider-dots__inner .slick-dots li button {
    background: #CED1D6;
    opacity: 0.9;
  }
  .main-slider-dots__inner .slick-dots li.slick-active button {
    background-color: #0047bb;
  }
  .advantages-section.section {
    padding-top: 80px;
  }
  .main-slider-dots__inner, .main-slider__info {
    max-width: 100%;
  }

}

@media screen and (max-width: 350px) {
  .main-slider__item:after {
    content: "";
    display: block;
    padding-top: 149%;
  }

  .info-menu .social-menu li {
    margin-right: 50px;
  }
}