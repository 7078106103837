@import '_vars.css';
@import '_base.css';

/* Attention seekers  */
@import 'attention_seekers/bounce.css';
@import 'attention_seekers/flash.css';
@import 'attention_seekers/pulse.css';
@import 'attention_seekers/rubberBand.css';
@import 'attention_seekers/shakeX.css';
@import 'attention_seekers/shakeY.css';
@import 'attention_seekers/headShake.css';
@import 'attention_seekers/swing.css';
@import 'attention_seekers/tada.css';
@import 'attention_seekers/wobble.css';
@import 'attention_seekers/jello.css';
@import 'attention_seekers/heartBeat.css';

/* Back entrances */
@import 'back_entrances/backInDown.css';
@import 'back_entrances/backInLeft.css';
@import 'back_entrances/backInRight.css';
@import 'back_entrances/backInUp.css';

/* Back exits */
@import 'back_exits/backOutDown.css';
@import 'back_exits/backOutLeft.css';
@import 'back_exits/backOutRight.css';
@import 'back_exits/backOutUp.css';

/* Bouncing entrances  */
@import 'bouncing_entrances/bounceIn.css';
@import 'bouncing_entrances/bounceInDown.css';
@import 'bouncing_entrances/bounceInLeft.css';
@import 'bouncing_entrances/bounceInRight.css';
@import 'bouncing_entrances/bounceInUp.css';

/* Bouncing exits  */
@import 'bouncing_exits/bounceOut.css';
@import 'bouncing_exits/bounceOutDown.css';
@import 'bouncing_exits/bounceOutLeft.css';
@import 'bouncing_exits/bounceOutRight.css';
@import 'bouncing_exits/bounceOutUp.css';

/* Fading entrances  */
@import 'fading_entrances/fadeIn.css';
@import 'fading_entrances/fadeInDown.css';
@import 'fading_entrances/fadeInDownBig.css';
@import 'fading_entrances/fadeInLeft.css';
@import 'fading_entrances/fadeInLeftBig.css';
@import 'fading_entrances/fadeInRight.css';
@import 'fading_entrances/fadeInRightBig.css';
@import 'fading_entrances/fadeInUp.css';
@import 'fading_entrances/fadeInUpBig.css';
@import 'fading_entrances/fadeInTopLeft.css';
@import 'fading_entrances/fadeInTopRight.css';
@import 'fading_entrances/fadeInBottomLeft.css';
@import 'fading_entrances/fadeInBottomRight.css';

/* Fading exits */
@import 'fading_exits/fadeOut.css';
@import 'fading_exits/fadeOutDown.css';
@import 'fading_exits/fadeOutDownBig.css';
@import 'fading_exits/fadeOutLeft.css';
@import 'fading_exits/fadeOutLeftBig.css';
@import 'fading_exits/fadeOutRight.css';
@import 'fading_exits/fadeOutRightBig.css';
@import 'fading_exits/fadeOutUp.css';
@import 'fading_exits/fadeOutUpBig.css';
@import 'fading_exits/fadeOutTopLeft.css';
@import 'fading_exits/fadeOutTopRight.css';
@import 'fading_exits/fadeOutBottomRight.css';
@import 'fading_exits/fadeOutBottomLeft.css';

/* Flippers */
@import 'flippers/flip.css';
@import 'flippers/flipInX.css';
@import 'flippers/flipInY.css';
@import 'flippers/flipOutX.css';
@import 'flippers/flipOutY.css';

/* Lightspeed */
@import 'lightspeed/lightSpeedInRight.css';
@import 'lightspeed/lightSpeedInLeft.css';
@import 'lightspeed/lightSpeedOutRight.css';
@import 'lightspeed/lightSpeedOutLeft.css';

/* Rotating entrances */
@import 'rotating_entrances/rotateIn.css';
@import 'rotating_entrances/rotateInDownLeft.css';
@import 'rotating_entrances/rotateInDownRight.css';
@import 'rotating_entrances/rotateInUpLeft.css';
@import 'rotating_entrances/rotateInUpRight.css';

/* Rotating exits */
@import 'rotating_exits/rotateOut.css';
@import 'rotating_exits/rotateOutDownLeft.css';
@import 'rotating_exits/rotateOutDownRight.css';
@import 'rotating_exits/rotateOutUpLeft.css';
@import 'rotating_exits/rotateOutUpRight.css';

/* Specials */
@import 'specials/hinge.css';
@import 'specials/jackInTheBox.css';
@import 'specials/rollIn.css';
@import 'specials/rollOut.css';

/* Zooming entrances */
@import 'zooming_entrances/zoomIn.css';
@import 'zooming_entrances/zoomInDown.css';
@import 'zooming_entrances/zoomInLeft.css';
@import 'zooming_entrances/zoomInRight.css';
@import 'zooming_entrances/zoomInUp.css';

/* Zooming exits */
@import 'zooming_exits/zoomOut.css';
@import 'zooming_exits/zoomOutDown.css';
@import 'zooming_exits/zoomOutLeft.css';
@import 'zooming_exits/zoomOutRight.css';
@import 'zooming_exits/zoomOutUp.css';

/* Sliding entrances */
@import 'sliding_entrances/slideInDown.css';
@import 'sliding_entrances/slideInLeft.css';
@import 'sliding_entrances/slideInRight.css';
@import 'sliding_entrances/slideInUp.css';

/* Sliding exits */
@import 'sliding_exits/slideOutDown.css';
@import 'sliding_exits/slideOutLeft.css';
@import 'sliding_exits/slideOutRight.css';
@import 'sliding_exits/slideOutUp.css';
