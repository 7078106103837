@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin textOverflow($overflow: ellipsis) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: $overflow;
}

@mixin centeredCoverBackground {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin centeredContainBackground {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}